/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {Feature, GeoJsonProperties, Position} from "geojson"
import {BoundingBox} from "../../common/utils/geoUtils"
import {LayerType} from "../parserTypes"
import FileParser from "./fileParser"
import Logger from "../../common/logger"
import {MetadataStore} from "../../global/metadataStore"
import Wgs84Utils, {LngLat} from "../../common/utils/wgs84Utils"
import MathUtils from "../../common/utils/mathUtils"
import Storage, {Settings} from "../../common/storage"

type PointColor = {
  point: LngLat
  color: string
}

export class FileParserJson extends FileParser {
  private readonly defaultSectionColor = "rgb(112,150,244)"

  // These are the colors of sections. Colors are rendered in this order, lower ones replace higher ones.
  private readonly sectionTypeColors: {[key: string]: string} = {
    SPEED_LIMIT: "rgb(142,142,142)",
    MOTORWAY: "rgb(205,47,47)",
    CARPOOL: "rgb(142,21,202)",
    TOLL_ROAD: "rgb(253,170,16)",
    TOLL: "rgb(253,115,16)",
    TOLL_VIGNETTE: "rgb(253,87,16)",
    URBAN: "rgb(33,87,223)",
    LOW_EMISSION_ZONE: "rgb(91,188,13)",
    TUNNEL: "rgb(65,216,180)",
    CAR_TRAIN: "rgb(244,109,161)",
    FERRY: "rgb(109,240,244)",
    PEDESTRIAN: "rgb(206,109,244)",
    UNPAVED: "rgb(223,164,75)"
  }

  // These are the thresholds for the speed colors.
  private readonly speedColorThresholds = [
    {speed: 130, color: "rgb(161,10,73)"},
    {speed: 110, color: "rgb(207,44,44)"},
    {speed: 90, color: "rgb(241,122,37)"},
    {speed: 70, color: "rgb(163,251,78)"},
    {speed: 50, color: "rgb(74,179,33)"},
    {speed: 30, color: "rgb(49,99,0)"},
    {speed: 0, color: "rgb(6,90,79)"}
  ]

  constructor() {
    super([".json"], LayerType.JSON, "JSON")
  }

  async parseFile(fileName: string, contents: string, onProgress: (percentage: number) => void): Promise<Feature[]> {
    Logger.log.info(`Parse JSON file: ${fileName}`)
    onProgress(0)

    let features: Feature[] = []
    const json = JSON.parse(contents)
    if (!("routes" in json) || !Array.isArray(json.routes)) {
      Logger.log.info(`No 'routes' found in JSON file`)
      return []
    }
    for (const route of json.routes) {
      if (!("legs" in route) || !Array.isArray(route.legs)) {
        Logger.log.info(`No 'legs' found in 'routes' item in JSON file`)
        return []
      }

      // Get all route points.
      const routePoints: [number, number][] = route.legs.flatMap((leg: any) =>
        leg.points.map((point: any) => [point.longitude, point.latitude] as [number, number])
      )

      // Calculate the route length in meters.
      const routeLengthInMeters = this.calculateRouteLengthInMeters(route)

      // Color the route sections.
      if (Storage.get(Settings.ShowRouteSectionTypes)) {
        features = this.getFeaturesColoredBySectionType(route, routePoints, routeLengthInMeters)
      } else {
        features = this.getFeaturesColoredByAverageSpeed(route, routePoints, routeLengthInMeters)
      }
    }
    onProgress(1)
    return features
  }

  private getFeaturesColoredByAverageSpeed(
    route: any,
    routePoints: [number, number][],
    routeLengthInMeters: number
  ): Feature[] {
    let features: Feature[] = []

    // Color the progress sections.
    let sectionIndex = 0
    let previousPointIndex = 0
    let previousDistanceInMeters = 0
    let previousTravelTimeinSeconds = 0
    for (const progress of route.progress) {
      if (!("pointIndex" in progress && "travelTimeInSeconds" in progress && "distanceInMeters" in progress)) {
        Logger.log.warning(
          `No 'pointIndex', 'travelTimeInSeconds' or 'distanceInMeters' found in 'progress' item in JSON file`
        )
        return []
      }

      // Get all points for this section.
      const currentPointIndex = progress.pointIndex
      const sectionPoints = routePoints.slice(previousPointIndex, currentPointIndex + 1)

      // Distance and travel time (for speed).
      const distanceInMetersDelta = progress.distanceInMeters - previousDistanceInMeters
      const travelTimeInSecondsDelta = progress.travelTimeInSeconds - previousTravelTimeinSeconds
      const speedKmPerHour = travelTimeInSecondsDelta
        ? Math.round((distanceInMetersDelta / travelTimeInSecondsDelta) * 3.6) // Convert m/s to km/h.
        : 0

      // Draw start of section.
      features.push({
        type: "Feature",
        geometry: {type: "Point", coordinates: sectionPoints[0]},
        properties: {
          "circle-radius": 3,
          "circle-color": this.colorFromSpeed(speedKmPerHour)
        }
      })

      // Define the properties for section itself.
      let properties: GeoJsonProperties = {
        "line-width": 4,
        "line-offset": 0,
        "line-color": this.defaultSectionColor,
        "line-outline": true
      }

      // Define the metadata for the LineString leg, so the user can click on leg segments.
      let extendedMetadata: GeoJsonProperties = {
        sectionIndex: sectionIndex,
        speedKmPerHour: speedKmPerHour,
        routeLengthInMeters: routeLengthInMeters
      }
      properties["line-color"] = this.colorFromSpeed(speedKmPerHour)

      // Store the metadata.
      const metadata = {
        layer: LayerType.JSON,
        bounds: this.boundsFromPoints(sectionPoints),
        ...extendedMetadata
      }
      const metadataKey = MetadataStore.store(metadata)

      // Store the leg LineString.
      features.push({
        type: "Feature",
        geometry: {
          type: "LineString",
          coordinates: sectionPoints
        },
        properties: {
          metadata: metadataKey,
          layer: LayerType.JSON,
          ...properties
        }
      })
      Logger.log.info(`Section ${sectionIndex}: ${distanceInMetersDelta} m at ${speedKmPerHour} km/h`)
      previousPointIndex = currentPointIndex
      previousDistanceInMeters += distanceInMetersDelta
      previousTravelTimeinSeconds += travelTimeInSecondsDelta
      ++sectionIndex
    }
    return features
  }

  private getFeaturesColoredBySectionType(
    route: any,
    routePoints: [number, number][],
    routeLengthInMeters: number
  ): Feature[] {
    let features: Feature[] = []

    // Plot default line for non-sectioned stretches.
    features.push({
      type: "Feature",
      properties: {
        "line-width": 2,
        "line-offset": 0,
        "line-color": this.defaultSectionColor,
        "line-outline": true
      },
      geometry: {
        type: "LineString",
        coordinates: routePoints
      }
    })

    // Draw each section.
    let sectionIndex = 0
    for (const sectionTypeToShow of Object.keys(this.sectionTypeColors)) {
      for (const section of route.sections) {
        if (!("sectionType" in section)) {
          Logger.log.warning(`No 'sectionType' found in 'section' item in JSON file`)
          return []
        }

        // Get the section type.
        const sectionType = section.sectionType.toUpperCase()
        if (sectionType !== sectionTypeToShow) {
          continue
        }

        // Get all points for this section.
        const sectionPoints = routePoints.slice(section.startPointIndex, section.endPointIndex + 1)

        // Show optional speed limit.
        const maxSpeedLimitInKmh: number = section.maxSpeedLimitInKmh
        let angle = 0
        if (maxSpeedLimitInKmh) {
          const start = sectionPoints[0]
          const end = sectionPoints[1]

          const lat1 = MathUtils.degreesToRadians(start[1])
          const lat2 = MathUtils.degreesToRadians(end[1])
          const deltaLng = MathUtils.degreesToRadians(end[0] - start[0])
          const y = Math.sin(deltaLng)
          const x = Math.log(Math.tan(Math.PI / 4 + lat2 / 2) / Math.tan(Math.PI / 4 + lat1 / 2))
          angle = MathUtils.radiansToDegrees(Math.atan2(y, x)) + 90
          if (angle > 90) {
            angle -= 180
          } else if (angle <= -90) {
            angle += 180
          }
        }
        features.push({
          type: "Feature",
          geometry: {
            type: "Point",
            coordinates: sectionPoints[0]
          },
          properties: {
            "circle-radius": 3,
            "circle-color": this.sectionTypeColors[sectionType],
            ...(maxSpeedLimitInKmh && {text: `${maxSpeedLimitInKmh} km/h`}),
            ...(maxSpeedLimitInKmh && {"text-color": "rgb(192,0,0)"}),
            ...(maxSpeedLimitInKmh && {"text-rotate": angle})
          }
        })

        // Calculate the section length in meters.
        let sectionLengthInMeters = 0
        for (let i = 1; i < sectionPoints.length; ++i) {
          sectionLengthInMeters += Wgs84Utils.distanceInMeters(
            {lng: sectionPoints[i - 1][0], lat: sectionPoints[i - 1][1]},
            {lng: sectionPoints[i][0], lat: sectionPoints[i][1]}
          )
        }

        // Determine color based on section type.
        const color =
          sectionType in this.sectionTypeColors ? this.sectionTypeColors[sectionType] : this.defaultSectionColor

        // Add metadata for the section.
        const metadata = {
          layer: LayerType.JSON,
          sectionIndex: sectionIndex,
          sectionType: sectionType,
          ...(maxSpeedLimitInKmh && {maxSpeedLimitInKmh: maxSpeedLimitInKmh}),
          sectionLength: `${sectionLengthInMeters.toFixed(0)}m`,
          routeLength: `${(routeLengthInMeters / 1000).toFixed(1)}km`,
          bounds: this.boundsFromPoints(sectionPoints)
        }
        const metadataKey = MetadataStore.store(metadata)

        const properties = {
          metadata: metadataKey,
          "line-width": 5,
          "line-offset": 0,
          "line-color": color,
          "line-outline": true
        }
        features.push({
          type: "Feature",
          properties: properties,
          geometry: {
            type: "LineString",
            coordinates: sectionPoints
          }
        })
        Logger.log.info(`Section ${sectionIndex}: ${sectionType} is ${Math.round(sectionLengthInMeters)} m`)
        ++sectionIndex
      }
    }
    return features
  }

  private calculateRouteLengthInMeters(route: any): number {
    let routeLengthInMeters = 0
    const routePoints = route.legs.flatMap((leg: any) =>
      leg.points.map((point: any) => [point.longitude, point.latitude] as [number, number])
    )

    for (let i = 1; i < routePoints.length; ++i) {
      routeLengthInMeters += Wgs84Utils.distanceInMeters(
        {lng: routePoints[i - 1][0], lat: routePoints[i - 1][1]},
        {lng: routePoints[i][0], lat: routePoints[i][1]}
      )
    }
    return Math.round(routeLengthInMeters)
  }

  private boundsFromPoints(positions: Position[]) {
    let bounds = BoundingBox.empty()
    for (const position of positions) {
      bounds = bounds.extendToLngLat({lng: position[0], lat: position[1]})
    }
    return bounds
  }

  private colorFromSpeed(speed: number) {
    // Search highest speed entry for color.
    let color = this.speedColorThresholds[0].color
    for (const threshold of this.speedColorThresholds) {
      if (speed >= threshold.speed) {
        color = threshold.color
        break
      }
    }
    return color
  }
}

export default FileParserJson
