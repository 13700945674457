/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {Feature} from "geojson"
import {Tool} from "./tool"
import {Point as MapLibrePoint} from "maplibre-gl"
import MapView from "../app/mapView"
import StringUtils from "../common/utils/stringUtils"
import UrlUtils from "../common/utils/urlUtils"
import {LayerWithoutId} from "../common/utils/mapLibreUtils"
import {LngLat} from "../common/utils/wgs84Utils"

export class TileEditorTool extends Tool {
  private readonly queryTileUrl: (point: MapLibrePoint) => URL | undefined
  private features: Feature[] = []

  constructor(map: MapView, queryTileUrl: (point: MapLibrePoint) => URL | undefined) {
    super("tileEditorTool", "Tile editor", map)
    this.queryTileUrl = queryTileUrl
  }

  onClick(location: LngLat, point: MapLibrePoint) {
    const url = this.queryTileUrl(point)
    if (url) {
      console.log(`URL: ${StringUtils.redactedString(url.toString())}`)
      window.open(`../tileEditor.html?source=${UrlUtils.urlToBase64(url.toString())}`)
    }
  }

  clear() {
    this.features = []
    this.refresh()
  }

  getLayers(): LayerWithoutId[] {
    return [
      {
        type: "fill",
        paint: {
          "fill-color": ["case", ["has", "fill-color"], ["get", "fill-color"], "rgb(36,180,31)"],
          "fill-opacity": ["case", ["has", "fill-opacity"], ["get", "fill-opacity"], 0.5]
        },
        filter: ["all", ["==", ["geometry-type"], "Polygon"]]
      },
      {
        type: "line",
        layout: {
          "line-cap": "round",
          "line-join": "round"
        },
        paint: {
          "line-color": ["case", ["has", "line-color"], ["get", "line-color"], "rgb(133,133,133)"],
          "line-width": ["case", ["has", "line-width"], ["get", "line-width"], 4]
        },
        filter: ["all", ["==", ["geometry-type"], "LineString"]]
      }
    ]
  }

  refresh() {
    this.draw(this.features)
  }
}

export default TileEditorTool
