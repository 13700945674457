/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {MapMouseEvent} from "maplibre-gl"
import MapView from "./mapView"
import DataCanvas from "./dataCanvas"
import Timeline, {TimeRange} from "./timeline"
import MenuBar from "../menu/menuBar"
import {LayerMenuItem, MenuItem, separatorMenuItem} from "../menu/menuTypes"
import {isRealLayer, LayerType} from "../parsers/parserTypes"
import {HelpWindow} from "../windows/helpWindow"
import ReleaseNotesWindow from "../windows/releaseNotesWindow"
import InspectorWindow from "../windows/inspectorWindow"
import ConsoleWindow from "../windows/consoleWindow"
import {Toolbox} from "../tools/toolbox"
import assert from "../common/assert"
import ProgressWindow from "../windows/progressWindow"
import {
  HTML_BUTTON_CLEAR_MAP,
  HTML_BUTTON_SAVE,
  HTML_BUTTON_SELECT_FILES,
  HTML_BUTTON_ZOOM_IN,
  HTML_BUTTON_ZOOM_OUT,
  HTML_DATA_INPUT,
  HTML_DATA_INPUT_CANCEL,
  HTML_DATA_INPUT_SUBMIT,
  HTML_DATA_INPUT_VALUE,
  HTML_MAP,
  HTML_MENU_ITEM_AUTO_SHOW_CONSOLE_WINDOW,
  HTML_MENU_ITEM_DOWNLOAD_ROUTING_API_RESPONSE,
  HTML_MENU_ITEM_GPX_TIMESTAMPS,
  HTML_MENU_ITEM_ROUTE_SHOW_SECTION_SPEEDS,
  HTML_MENU_ITEM_ROUTE_SHOW_SECTION_TYPES,
  HTML_MENU_ITEM_SHOW_MOUSE_POSITION,
  HTML_MENU_ITEM_SKIP_COMMENTS,
  HTML_MENU_ITEM_TILE_GRID,
  HTML_MENU_ITEM_USE_LOCAL_TIME,
  HTML_MENU_ITEM_USE_ORBIS_ROUTING_API,
  HTML_MENUBAR,
  HTML_PROGRESS_BAR,
  HTML_TIMELINE,
  HTML_TOOLBOX,
  HTML_VERSION_MARKER,
  HTML_WINDOW_CONSOLE,
  HTML_WINDOW_CONSOLE_TITLE,
  HTML_WINDOW_CONTENT_HELP,
  HTML_WINDOW_CONTENT_INSPECTOR,
  HTML_WINDOW_CONTENT_PROGRESS,
  HTML_WINDOW_CONTENT_RELEASE_NOTES,
  HTML_WINDOW_HELP,
  HTML_WINDOW_HELP_TITLE,
  HTML_WINDOW_INSPECTOR,
  HTML_WINDOW_INSPECTOR_TITLE,
  HTML_WINDOW_PROGRESS,
  HTML_WINDOW_RELEASE_NOTES,
  HTML_WINDOW_RELEASE_NOTES_TITLE,
  HTML_WINDOW_SOURCE,
  HTML_WINDOW_SOURCE_TITLE,
  HTML_WINDOW_TITLE_BAR_INSPECTOR
} from "../html/htmlElementId"
import {
  HTML_CLASS_PANEL,
  HTML_CLASS_PANEL_DRAG_BAR,
  HTML_CLASS_WINDOW,
  HTML_CLASS_WINDOW_TITLE_BAR
} from "../html/htmlClassId"
import Logger from "../common/logger"
import Storage, {Settings} from "../common/storage"
import {ExceptionHandler} from "./exceptionHandler"
import {Html} from "../html/html"
import LayersPanel from "../panels/layersPanel"
import DataFilesPanel from "../panels/dataFilesPanel"
import TileLevelFiltersPanel from "../panels/tileLevelFiltersPanel"
import SourceWindow from "../windows/sourceWindow"
import {Feature} from "geojson"
import {MetadataStore} from "../global/metadataStore"
import DataStore from "../global/dataStore"
import {Mutex} from "async-mutex"
import YesNoDialog from "../dialogs/yesNoDialog"

/**
 * This class defines the application. It sets up the entire application and supports an exception handler to catch exceptions during
 * execution.
 */
export class App {
  private readonly dataCanvas: DataCanvas // Map opverlay with all imported data.
  private readonly mapView: MapView // The map itself.

  private readonly menuBar: MenuBar // Top menu bar.
  private readonly toolbox: Toolbox // Toolbox with all tool icons.
  private readonly timeline: Timeline // Timeline (bottom) with all events.

  private readonly helpWindow: HelpWindow // Window handles.
  private readonly releaseNotesWindow: ReleaseNotesWindow
  private readonly inspectorWindow: InspectorWindow
  private readonly consoleWindow: ConsoleWindow
  private readonly sourceWindow: SourceWindow
  private readonly progressWindow: ProgressWindow

  private readonly tileLevelFiltersPanel: TileLevelFiltersPanel // Panel for tile level filters.
  private readonly layersPanel: LayersPanel // Panel for toggling layers.
  private readonly dataFilesPanel: DataFilesPanel // Panel for selecting data files.

  private readonly mousePositionLoggingIntervalInMillis = 50
  private mousePositionLastLoggedTime = 0

  private keyboardShortcutsEnabled = true // Set to false when expecting keyboard input in a dialog.

  constructor() {
    console.log("Constructing app...")

    // Install the exception handler.
    window.onerror = ExceptionHandler.handleException

    // Create the help window. Load the contents async with "initialize()".
    this.helpWindow = new HelpWindow("Help", HTML_WINDOW_HELP, HTML_WINDOW_HELP_TITLE, HTML_WINDOW_CONTENT_HELP)

    // Create the release notes window. Load the contents async with "initialize()".
    this.releaseNotesWindow = new ReleaseNotesWindow(
      "Release notes",
      HTML_WINDOW_RELEASE_NOTES,
      HTML_WINDOW_RELEASE_NOTES_TITLE,
      HTML_WINDOW_CONTENT_RELEASE_NOTES
    )

    // Create the progress window.
    this.progressWindow = new ProgressWindow(HTML_WINDOW_PROGRESS, HTML_WINDOW_CONTENT_PROGRESS, HTML_PROGRESS_BAR)

    // Create the map view and the data canvas (which uses the map view).
    this.mapView = new MapView("map", this.onClick, this.onContextMenu, this.onMouseMove)
    this.dataCanvas = new DataCanvas(this.mapView, this.progressWindow)

    // Create the inspector window.
    this.inspectorWindow = new InspectorWindow(
      "Inspector",
      HTML_WINDOW_INSPECTOR,
      HTML_WINDOW_INSPECTOR_TITLE,
      HTML_WINDOW_CONTENT_INSPECTOR,
      HTML_WINDOW_TITLE_BAR_INSPECTOR,
      (feature?: Feature) => MetadataStore.retrieve(feature?.properties?.metadata) ?? {},
      (feature?: Feature) => this.toolbox.tools.dataSelectorTool.draw(feature ? [feature] : []),
      (fileId: string, lineNumer: number) => {
        Logger.log.info(`Show source: ${fileId}, line ${lineNumer}`)
        const sourceLinesWithTimes = DataStore.getSourceLinesWithTime(fileId)
        this.sourceWindow.setSourceLinesWithTime(fileId, sourceLinesWithTimes, lineNumer)
        if (Storage.get(Settings.AutoShowSource)) {
          this.sourceWindow.setVisible()
        }
      }
    )

    // Create the inspector window.
    this.consoleWindow = new ConsoleWindow("Console", HTML_WINDOW_CONSOLE, HTML_WINDOW_CONSOLE_TITLE)

    // Create the source window.
    this.sourceWindow = new SourceWindow("Source", HTML_WINDOW_SOURCE, HTML_WINDOW_SOURCE_TITLE, this.dataCanvas)

    // Create the menu definitions.
    const viewMenuDefinition = this.setupViewMenu()
    const othersMenuDefinition = this.setupOthersMenu()

    const clearButton = Html.getDefinedHtmlElementById(HTML_BUTTON_CLEAR_MAP)
    clearButton.addEventListener("click", () => this.clearMap())

    // Create the timeline region.
    this.timeline = new Timeline(
      HTML_TIMELINE,
      HTML_BUTTON_ZOOM_IN,
      HTML_BUTTON_ZOOM_OUT,
      HTML_BUTTON_SAVE,
      this.inspectorWindow,
      this.setTimeRange
    )

    // Create the panels.
    this.tileLevelFiltersPanel = new TileLevelFiltersPanel(this.dataCanvas, this.timeline, this.draw)
    this.dataFilesPanel = new DataFilesPanel(this.dataCanvas, this.timeline, this.draw)
    this.layersPanel = new LayersPanel(this.dataCanvas, this.timeline, this.sourceWindow, this.draw)

    // Create the toolbox with all tools.
    this.toolbox = new Toolbox(
      this.mapView,
      this.inspectorWindow,
      this.sourceWindow,
      this.timeline.shrinkTimeRange,
      this.toggleHelpWindow
    )
    this.toolbox.resetCurrentTool()

    // Create the menu bar.
    const layersMenuDefinition = this.setupLayersMenu()
    this.menuBar = new MenuBar(
      this.mapView.getMapsMenu(),
      viewMenuDefinition,
      layersMenuDefinition,
      othersMenuDefinition,
      this.mapView.selectMap
    )

    // Add the click handler for the menu. This needs to be "window" to catch all clicks, as the menu has dropxdowns.
    window.addEventListener("click", (e) => this.menuBar.onWindowClick(e))

    // Add the keyboard shortcut in the color swtches for the layers.
    layersMenuDefinition.forEach((item) => {
      if (item.shortcut) {
        const layerType = item.id as LayerType
        const elm = Html.getDefinedHtmlElementById(Html.htmlElementIdForColor(layerType))
        elm.innerText = item.shortcut
        if (item.textColor) {
          elm.style.color = item.textColor
        }
      }
    })
    this.setupKeyboardListeners([viewMenuDefinition, layersMenuDefinition, othersMenuDefinition].flat())

    // Update the menu items.
    MenuBar.updateMenuItemToggle(HTML_MENU_ITEM_TILE_GRID, this.mapView.showTileGrid())
    MenuBar.updateMenuItemToggle(HTML_MENU_ITEM_ROUTE_SHOW_SECTION_SPEEDS, !Storage.get(Settings.ShowRouteSectionTypes))
    MenuBar.updateMenuItemToggle(HTML_MENU_ITEM_ROUTE_SHOW_SECTION_TYPES, Storage.get(Settings.ShowRouteSectionTypes))
    MenuBar.updateMenuItemToggle(HTML_MENU_ITEM_GPX_TIMESTAMPS, Storage.get(Settings.AddGpxTimestamps))
    MenuBar.updateMenuItemToggle(HTML_MENU_ITEM_SHOW_MOUSE_POSITION, Storage.get(Settings.ShowMousePosition))
    MenuBar.updateMenuItemToggle(HTML_MENU_ITEM_USE_LOCAL_TIME, !Storage.get(Settings.UseTimeFormatUTC))
    MenuBar.updateMenuItemToggle(HTML_MENU_ITEM_SKIP_COMMENTS, Storage.get(Settings.SkipComments))
    MenuBar.updateMenuItemToggle(HTML_MENU_ITEM_AUTO_SHOW_CONSOLE_WINDOW, Storage.get(Settings.AutoShowConsole))
    MenuBar.updateMenuItemToggle(
      HTML_MENU_ITEM_DOWNLOAD_ROUTING_API_RESPONSE,
      Storage.get(Settings.DownloadRoutingApiResponse)
    )
    MenuBar.updateMenuItemToggle(HTML_MENU_ITEM_USE_ORBIS_ROUTING_API, Storage.get(Settings.UseOrbisRoutingApi))

    // Update the layer panel.
    Object.entries(this.dataCanvas.layerStates).forEach(([layerTypeString, item]) => {
      const layerType = layerTypeString as LayerType
      if (isRealLayer(layerType)) {
        this.layersPanel.updateOption(layerType, item.show)
      }
    })

    // Allow dropping files on these elements and classes.
    Html.makeHtmlElementDroppable(HTML_MAP, this.handleDropOnCanvasOrSelectedFile)
    Html.makeHtmlElementDroppable(HTML_MENUBAR, this.handleDropOnCanvasOrSelectedFile)
    Html.makeHtmlElementDroppable(HTML_TIMELINE, this.handleDropOnCanvasOrSelectedFile)
    Html.makeHtmlElementDroppable(HTML_TOOLBOX, this.handleDropOnCanvasOrSelectedFile)
    Html.makeHtmlClassDroppable(HTML_CLASS_PANEL, this.handleDropOnCanvasOrSelectedFile)
    Html.makeHtmlClassDroppable(HTML_CLASS_WINDOW, this.handleDropOnCanvasOrSelectedFile)

    // Add the "select files" button.
    const selectFilesButton = Html.getDefinedHtmlElementById(HTML_BUTTON_SELECT_FILES)
    selectFilesButton.addEventListener("click", () => this.selectFiles())

    // These require the menu options to be available:
    this.layersPanel.update()
    this.helpWindow.setVisible(false)
    this.inspectorWindow.setVisible(false)
    this.consoleWindow.setVisible(false)
    this.sourceWindow.setVisible(false)

    // Automatically show help/what's new if this is a new version.
    if (Storage.hasApplicationVersionChanged()) {
      Storage.updateApplicationVersion()
      this.releaseNotesWindow.setVisible()
    } else {
      this.releaseNotesWindow.setVisible(false)
    }

    // Add the clickable version marker in the menu bar (shows release notes).
    const versionMarker = Html.getDefinedHtmlElementById(HTML_VERSION_MARKER)
    versionMarker.addEventListener("click", () =>
      this.releaseNotesWindow.setVisible(!this.releaseNotesWindow.isVisible())
    )

    // Store the panel positions and make them draggable.
    Html.storeHtmlElementPosition(HTML_TOOLBOX)
    Html.storeHtmlClassPositions(HTML_CLASS_PANEL)
    Html.storeHtmlClassPositions(HTML_CLASS_WINDOW)
    Html.makeHtmlElementDraggable(HTML_TOOLBOX)
    Html.makeHtmlClassDraggable(HTML_CLASS_PANEL_DRAG_BAR, HTML_CLASS_PANEL)
    Html.makeHtmlClassDraggable(HTML_CLASS_WINDOW_TITLE_BAR, HTML_CLASS_WINDOW)

    // Make sure all panels are positioned properly.
    this.layersPanel.resizePanels()
  }

  /**
   * Initialize the application. This is an async function, which returns immediately.
   * Most of the initialization is done in the constructor, but some things need to be done async.
   *
   * The reason to not move all initialization here, is that some of the initialized properties
   * are declared as "readonly", so they can **only** be initialized in the constructor.
   */
  async initialize() {
    console.log("Loading content files async...")
    this.helpWindow.loadContent()
    this.releaseNotesWindow.loadContent()
  }

  /**
   * Callback function for dropping files on canvas (must be lambda), or a selected file (which is passed
   * as an event).
   * @param dragEvent event.
   */
  private readonly mutexDropOnCanvas = new Mutex()
  private readonly handleDropOnCanvasOrSelectedFile = async (dragEvent: DragEvent) => {
    // Do not allow simultaneous file dropping due to potential race conditions in the loaded data structures.
    this.mutexDropOnCanvas.runExclusive(async () => {
      await this.dataCanvas.handleDrop(
        dragEvent,
        () => {
          this.resetToolsAndKeepData()
          this.layersPanel.update()
          this.dataFilesPanel.update()
          // Auto-zoom after dropping, if allowed.
          this.draw(false)
        },
        () => {
          this.layersPanel.update()
          this.dataFilesPanel.update()
          // Auto-zoom after dropping, if allowed.
          this.draw(true)
        }
      )
    })
  }

  /**
   * Allow the user to select a data file from the file system.
   */
  private readonly selectFiles = () => {
    const input = document.createElement("input")
    input.type = "file"
    input.multiple = true
    input.accept = ".ttp,.gpx,.json,.geojson,.csv,.txt,.log,.http,.xml,.output,.diff"
    input.addEventListener("change", (event) => {
      const files = (event.target as HTMLInputElement).files
      if (files) {
        const dataTransfer = new DataTransfer()
        for (const element of files) {
          dataTransfer.items.add(element)
        }
        const dragEvent = new DragEvent("drop", {
          dataTransfer: dataTransfer,
          bubbles: true,
          cancelable: true
        })
        // Simulate drop event for the selected files.
        this.handleDropOnCanvasOrSelectedFile(dragEvent).then(() => {})
      }
    })
    // Open file selector dialog with a slight delay (helps mobile browsers).
    setTimeout(() => {
      input.click()
    }, 100)
  }

  /**
   * Mouse click handler. Needs to be a lambda, not a regular function, to avoid binding issues.
   * @param event Mouse event.
   */
  private readonly onClick = (event: MapMouseEvent) => {
    const location = event.lngLat
    const point = event.point
    this.toolbox.currentTool.onClick(location, point)
  }

  /**
   * Mouse click handler. Needs to be a lambda, not a regular function, to avoid binding issues.
   * @param event Mouse event.
   */
  private readonly onContextMenu = (event: MapMouseEvent) => {
    const location = event.lngLat
    const point = event.point
    this.toolbox.currentTool.onContextMenu(location, point)
  }

  /**
   * Mouse move  handler. Needs to be a lambda, not a regular function, to avoid binding issues.
   * @param event Mouse event.
   */
  private readonly onMouseMove = (event: MapMouseEvent) => {
    if (Storage.get(Settings.ShowMousePosition)) {
      const now = Date.now()
      if (now > this.mousePositionLastLoggedTime + this.mousePositionLoggingIntervalInMillis) {
        this.mousePositionLastLoggedTime = now
        this.timeline.drawCoordinate(event.lngLat)
      }
    }
  }

  /**
   * Toggle the default MapLibre tile grid.
   */
  private readonly toggleShowTileGrid = () => {
    this.mapView.toggleShowTileGrid()
    MenuBar.updateMenuItemToggle(HTML_MENU_ITEM_TILE_GRID, this.mapView.showTileGrid())
  }

  /**
   * Toggle help window. NNeeds to be a lambda, not a regular function, to avoid binding issues.
   */
  private readonly toggleHelpWindow = () => {
    this.helpWindow.setVisible(!this.helpWindow.isVisible())
    if (this.helpWindow.isVisible()) {
      this.releaseNotesWindow.setVisible(false)
    }
  }

  private readonly toggleOptionSkipComments = () => {
    Storage.toggle(Settings.SkipComments)
    MenuBar.updateMenuItemToggle(HTML_MENU_ITEM_SKIP_COMMENTS, Storage.get(Settings.SkipComments))
  }

  private readonly toggleOptionDownloadRoutingApiResponse = () => {
    Storage.toggle(Settings.DownloadRoutingApiResponse)
    MenuBar.updateMenuItemToggle(
      HTML_MENU_ITEM_DOWNLOAD_ROUTING_API_RESPONSE,
      Storage.get(Settings.DownloadRoutingApiResponse)
    )
  }

  private readonly mutexUseOrbisRoutingApi = new Mutex()
  private readonly toggleOptionUseOrbisRoutingApi = () => {
    Storage.toggle(Settings.UseOrbisRoutingApi)
    MenuBar.updateMenuItemToggle(HTML_MENU_ITEM_USE_ORBIS_ROUTING_API, Storage.get(Settings.UseOrbisRoutingApi))
    this.mutexUseOrbisRoutingApi.runExclusive(() => {
      this.toolbox.tools.routeCreatorJsonTool.requestRouteAndRefresh()
    })
  }

  private readonly mutexShowSectionTypes = new Mutex()
  private readonly setOptionRouteShowSectionTypes = (showSectionTypes: boolean) => {
    Storage.set(Settings.ShowRouteSectionTypes, showSectionTypes)
    MenuBar.updateMenuItemToggle(HTML_MENU_ITEM_ROUTE_SHOW_SECTION_SPEEDS, !showSectionTypes)
    MenuBar.updateMenuItemToggle(HTML_MENU_ITEM_ROUTE_SHOW_SECTION_TYPES, showSectionTypes)
    // Refresh routes, but make sure we don't re-enter the critical section.
    this.mutexShowSectionTypes.runExclusive(() => {
      this.toolbox.tools.routeCreatorJsonTool.requestRouteAndRefresh()
    })
  }

  private readonly toggleOptionGpxTimestamps = () => {
    Storage.toggle(Settings.AddGpxTimestamps)
    MenuBar.updateMenuItemToggle(HTML_MENU_ITEM_GPX_TIMESTAMPS, Storage.get(Settings.AddGpxTimestamps))
  }

  private readonly toggleOptionShowMousePosition = () => {
    Storage.toggle(Settings.ShowMousePosition)
    MenuBar.updateMenuItemToggle(HTML_MENU_ITEM_SHOW_MOUSE_POSITION, Storage.get(Settings.ShowMousePosition))
    this.timeline.draw()
  }

  private readonly toggleOptionUseLocalTime = () => {
    Storage.toggle(Settings.UseTimeFormatUTC)
    MenuBar.updateMenuItemToggle(HTML_MENU_ITEM_USE_LOCAL_TIME, !Storage.get(Settings.UseTimeFormatUTC))
    this.draw(true)
  }

  private readonly toggleOptionAutoShowConsole = () => {
    Storage.toggle(Settings.AutoShowConsole)
    MenuBar.updateMenuItemToggle(HTML_MENU_ITEM_AUTO_SHOW_CONSOLE_WINDOW, Storage.get(Settings.AutoShowConsole))
    if (Storage.get(Settings.AutoShowConsole)) {
      Logger.log.show()
    }
  }

  /**
   * Toggle the visibility of tile levelsm based on a digit.
   * @param key The digit.
   */
  private handleKeyDigit(key: KeyboardEvent) {
    if (key.key < "0" || key.key > "9") {
      return false
    }
    if (key.key >= "0" && key.key <= "7") {
      this.tileLevelFiltersPanel.toggleOption(parseInt(key.key) + 10)
    } else if (key.key >= "8" && key.key <= "9") {
      this.tileLevelFiltersPanel.toggleOption(parseInt(key.key))
    }
    return true
  }

  /**
   * Install the keyboard handler for menu item shortcuts.
   * @param menuDefinitions List of menu items.
   */
  private setupKeyboardListeners(menuDefinitions: MenuItem[]) {
    document.body.addEventListener("keydown", (keyboardEvent: KeyboardEvent) => {
      // Avoid listening to keyboard shortcuts when typing in an input field, for example for the API key.
      if (!this.keyboardShortcutsEnabled) {
        return
      }
      if (keyboardEvent.altKey || keyboardEvent.ctrlKey || keyboardEvent.metaKey) {
        return
      }

      // Digits 0-9 are handled separately.
      if (!this.handleKeyDigit(keyboardEvent)) {
        const menuItem = menuDefinitions.find(
          (item) =>
            ("key" in item && keyboardEvent.key === `${item.key}`) ||
            ("shortcut" in item && keyboardEvent.code === `Key${item.shortcut}`)
        )
        if (menuItem && "action" in menuItem) {
          keyboardEvent.preventDefault()
          menuItem.action()
        }
      }
    })
  }

  /**
   * Allow the user to enter a custom HTTP overhead size.
   */
  private enterDefaultHttpOverheadSize() {
    const addEventListeners = () => {
      document.body.addEventListener("keydown", handleKeydown)
      Html.getDefinedHtmlElementById(HTML_DATA_INPUT_SUBMIT).addEventListener("click", submitDialog)
      Html.getDefinedHtmlElementById(HTML_DATA_INPUT_CANCEL).addEventListener("click", cancelDialog)
    }

    const removeEventListeners = () => {
      document.body.removeEventListener("keydown", handleKeydown)
      Html.getDefinedHtmlElementById(HTML_DATA_INPUT_SUBMIT).removeEventListener("click", submitDialog)
      Html.getDefinedHtmlElementById(HTML_DATA_INPUT_CANCEL).removeEventListener("click", cancelDialog)
    }

    const submitDialog = () => {
      const input = Html.getDefinedHtmlElementById(HTML_DATA_INPUT_VALUE) as HTMLInputElement
      const number = parseInt(input.value)
      if (number !== undefined && !isNaN(number) && number >= 0 && number <= 16 * 1024) {
        removeEventListeners()
        Storage.setHttpOverheadSizeInBytes(number)
        dialog.style.display = "none"
        this.keyboardShortcutsEnabled = true
        this.timeline.recalculateSizesAndDraw()
        this.layersPanel.update()
        this.layersPanel.update()
      } else {
        alert("Please enter a valid number between 0-16384.")
      }
    }

    const cancelDialog = () => {
      removeEventListeners()
      const dialog = Html.getDefinedHtmlElementById(HTML_DATA_INPUT)
      dialog.style.display = "none"
      this.keyboardShortcutsEnabled = true
    }

    const handleKeydown = (keyboardEvent: KeyboardEvent) => {
      // Do not prevent further handling of keys with keyboardEvent.preventDefault().
      if (keyboardEvent.key === "Enter") {
        submitDialog()
      } else if (keyboardEvent.key === "Escape") {
        cancelDialog()
      }
    }

    this.keyboardShortcutsEnabled = false
    const dialog = Html.getDefinedHtmlElementById(HTML_DATA_INPUT)
    dialog.style.display = "block"
    const value = Html.getDefinedHtmlElementById(HTML_DATA_INPUT_VALUE) as HTMLInputElement
    value.value = Storage.getHttpOverheadSizeInBytes().toString()
    value.focus()
    value.select()

    addEventListeners()
  }

  /**
   * Create a GPX file from a routing response file.
   */
  private createGpxFromRoutingResponseFile() {
    const input = document.createElement("input")
    input.type = "file"
    input.accept = ".json, .txt"
    input.addEventListener("change", (event) => {
      const file = (event.target as HTMLInputElement).files?.[0]
      if (file) {
        const reader = new FileReader()
        reader.onload = (e) => {
          const content = e.target?.result as string
          this.toolbox.tools.routeCreatorGpxTool.createGpxFromRoutingResponse(file.name, content)
        }
        reader.readAsText(file)
      }
    })
    input.click()
  }

  /**
   * Reset the state of tools (for example, after pressing Escape).
   */
  private resetToolsAndKeepData(keepRoutes = true) {
    this.toolbox.tools.dataSelectorTool.clear()
    this.toolbox.tools.distanceCalculatorTool.prepareNextMeasurement()
    keepRoutes
      ? this.toolbox.tools.routeCreatorJsonTool.finishCurrentRoute()
      : this.toolbox.tools.routeCreatorJsonTool.clear()
    this.toolbox.tools.routeCreatorGpxTool.clear()
    this.toolbox.tools.tileEditorTool.clear()
    this.helpWindow.setVisible(false)
    this.releaseNotesWindow.setVisible(false)
    this.inspectorWindow.setVisible(false)
    this.sourceWindow.setVisible(false)
    this.timeline.resetTimelineTimeRange()
  }

  /**
   * Clear the map and its imported data structures.
   */
  private readonly clearMap = () => {
    Html.resetHTMLElementPositions()
    this.dataCanvas.clear()
    this.toolbox.tools.dataSelectorTool.clear()
    this.toolbox.tools.distanceCalculatorTool.clear()
    this.toolbox.tools.routeCreatorJsonTool.clear()
    this.toolbox.tools.routeCreatorGpxTool.clear()
    this.toolbox.tools.tileEditorTool.clear()
    this.toolbox.resetCurrentTool()
    this.consoleWindow.clear()
    this.consoleWindow.setVisible(false)
    this.sourceWindow.clear()
    this.sourceWindow.setVisible(false)
    this.timeline.clear()
    this.layersPanel.update()
    this.dataFilesPanel.update()
    this.draw()
  }

  /**
   * Undo the last action. This is often a very simplistic undo, really.
   */
  private undo() {
    Logger.log.info("Undo last action")
    this.toolbox.currentTool.undo && this.toolbox.currentTool.undo()
    this.draw(true)
  }

  /**
   * Callback for (re)drawing the screen. This is an async function, which returns immediately.
   * @param inhibitAutoZoom If true, do not auto-zoom.
   * @param onFinished Called when finished drawing.
   */
  private readonly draw = async (inhibitAutoZoom: boolean = false, onFinished: () => void = () => {}) => {
    try {
      this.layersPanel.update() // Update sizes and stats of layers panel.
      this.timeline.replaceAllEvents(this.dataCanvas.getEventsFromFeatures(), inhibitAutoZoom)
      this.inspectorWindow.update()
      this.sourceWindow.update()
    } finally {
      onFinished()
    }
  }

  private readonly mutexSetTimeRange = new Mutex()
  private readonly setTimeRange = async (timeRange: TimeRange, forceUpdate: boolean, inhibitAutoZoom: boolean) => {
    this.mutexSetTimeRange.runExclusive(async () => {
      // Set the active time range for the data canvas.
      this.dataCanvas.setActiveTimeRange(timeRange)

      if (forceUpdate) {
        try {
          this.progressWindow.show("Analyzing requests for potential duplicates...")
          await this.dataCanvas.highlightPotentialDuplicates()
        } finally {
          this.progressWindow.hide()
        }
      }

      // Zoom map, if auto-zoom is enabled and zooming is not inhibited.
      if (!inhibitAutoZoom && Storage.get(Settings.AutoZoom)) {
        this.dataCanvas.zoomToBounds()
      }
    })
  }

  /**
   * Define the view menu.
   */
  private setupViewMenu(): MenuItem[] {
    const viewMenuItems: MenuItem[] = [
      {
        key: "Backspace",
        shortcut: "Backspace",
        name: "Undo last point",
        action: () => this.undo()
      },
      {
        key: "Escape",
        shortcut: "Esc",
        name: "Reset action",
        action: () => this.resetToolsAndKeepData()
      },
      {
        shortcut: "C",
        name: "Clear map",
        action: () => this.clearMap()
      },
      {
        key: "",
        shortcut: "+",
        name: "Zoom in (use Shift+ for more)",
        action: () => this.mapView.zoomIn()
      },
      {
        key: "",
        shortcut: "-",
        name: "Zoom out (use Shift- for more)",
        action: () => this.mapView.zoomOut()
      }
    ]

    const toggleMenuItems: MenuItem[] = [
      {
        name: "Route API: show average speeds",
        id: "route-show-section-speeds",
        action: () => this.setOptionRouteShowSectionTypes(false)
      },
      {
        name: "Route API: show road section types",
        id: "route-show-section-types",
        action: () => this.setOptionRouteShowSectionTypes(true)
      },
      separatorMenuItem,
      {
        shortcut: "Z",
        name: "Auto-zoom to extents of data",
        id: "auto-zoom",
        action: () => this.layersPanel.toggleAutoZoom()
      },
      {
        shortcut: "S",
        name: "Auto-show log source window",
        id: "auto-show-source",
        action: () => this.layersPanel.toggleAutoShowSource()
      },
      {
        name: "Show mouse position",
        id: "show-mouse-position",
        action: () => this.toggleOptionShowMousePosition()
      },
      {
        key: "\\",
        shortcut: "\\",
        name: "Toggle all layers on/off",
        id: "toggle-all-layers",
        action: () => this.layersPanel.toggleAllLayers()
      },
      {
        name: "Show MapVis tile grid",
        id: "tile-grid",
        action: () => this.toggleShowTileGrid()
      },
      {
        name: "Write timestamps to GPX files",
        id: "gpx-timestamps",
        action: () => this.toggleOptionGpxTimestamps()
      },
      {
        name: "Use UTC (instead of local time)",
        id: "use-local-time",
        action: () => this.toggleOptionUseLocalTime()
      }
    ]

    const windowMenuItems: MenuItem[] = [
      {
        key: "KeyM",
        shortcut: "M",
        name: "Show inspector window...",
        id: HTML_WINDOW_INSPECTOR,
        action: () => this.inspectorWindow.setVisible(!this.inspectorWindow.isVisible())
      },
      {
        key: "KeyO",
        shortcut: "O",
        name: "Show console window...",
        id: HTML_WINDOW_CONSOLE,
        action: () => this.consoleWindow.setVisible(!this.consoleWindow.isVisible())
      },
      {
        key: "KeyU",
        shortcut: "U",
        name: "Show source window...",
        id: HTML_WINDOW_SOURCE,
        action: () => this.sourceWindow.setVisible(!this.sourceWindow.isVisible())
      }
    ]
    return [viewMenuItems, separatorMenuItem, toggleMenuItems, separatorMenuItem, windowMenuItems].flat()
  }

  /**
   * Define the layers menu.
   */
  private setupLayersMenu(): LayerMenuItem[] {
    // Note: the IDs of these menu items must be the keys of LayerType.
    const layersMenuItems: LayerMenuItem[] = [
      {
        id: "MapVis3D",
        name: "MapVis: 3D",
        shortcut: "D",
        action: () => this.layersPanel.toggleLayer(LayerType.MapVis3D)
      },
      {
        id: "MapVisBasicMap",
        name: "MapVis: basic map",
        shortcut: "B",
        action: () => this.layersPanel.toggleLayer(LayerType.MapVisBasicMap)
      },
      {
        id: "MapVisFlow",
        name: "MapVis: flow",
        shortcut: "F",
        textColor: "black",
        action: () => this.layersPanel.toggleLayer(LayerType.MapVisFlow)
      },
      {
        id: "MapVisHillshade",
        name: "MapVis: hillshade",
        shortcut: "H",
        action: () => this.layersPanel.toggleLayer(LayerType.MapVisHillshade)
      },
      {
        id: "MapVisIncidents",
        name: "MapVis: incidents",
        shortcut: "I",
        action: () => this.layersPanel.toggleLayer(LayerType.MapVisIncidents)
      },
      {
        id: "MapVisSatellite",
        name: "MapVis: satellite",
        shortcut: "L",
        action: () => this.layersPanel.toggleLayer(LayerType.MapVisSatellite)
      },
      {
        id: "MapVisStyle",
        name: "MapVis: style",
        shortcut: "Y",
        action: () => this.layersPanel.toggleLayer(LayerType.MapVisStyle)
      },
      {
        id: "NdsLive",
        name: "NDS.Live",
        shortcut: "N",
        action: () => this.layersPanel.toggleLayer(LayerType.NdsLive)
      },
      {
        id: "NdsClassicRegion",
        name: "NDS.Classic region",
        action: () => this.layersPanel.toggleLayer(LayerType.NdsClassicRegion)
      },
      {
        id: "NdsClassicOther",
        name: "NDS.Classic other",
        action: () => this.layersPanel.toggleLayer(LayerType.NdsClassicOther)
      },
      {
        id: "Nk2NavTiles",
        name: "NK2 NavTiles",
        shortcut: "T",
        action: () => this.layersPanel.toggleLayer(LayerType.Nk2NavTiles)
      },
      {
        id: "Nk2LaneTiles",
        name: "NK2 LaneTiles",
        textColor: "black",
        action: () => this.layersPanel.toggleLayer(LayerType.Nk2LaneTiles)
      },
      {
        id: "ApiAutoComplete",
        name: "API: Auto-complete",
        shortcut: "A",
        action: () => this.layersPanel.toggleLayer(LayerType.ApiAutoComplete)
      },
      {
        id: "ApiEv",
        name: "API: EV",
        action: () => this.layersPanel.toggleLayer(LayerType.ApiEv)
      },
      {
        id: "ApiParking",
        name: "API: Parking",
        action: () => this.layersPanel.toggleLayer(LayerType.ApiParking)
      },
      {
        id: "ApiRevGeocode",
        name: "API: Rev.geocode",
        textColor: "black",
        action: () => this.layersPanel.toggleLayer(LayerType.ApiRevGeocode)
      },
      {
        id: "ApiRouting",
        name: "API: Routing",
        shortcut: "R",
        action: () => this.layersPanel.toggleLayer(LayerType.ApiRouting)
      },
      {
        id: "ApiSearch",
        name: "API: Search",
        shortcut: "Q",
        textColor: "black",
        action: () => this.layersPanel.toggleLayer(LayerType.ApiSearch)
      },
      {
        id: "ApiTpeg",
        name: "API: TPEG",
        textColor: "black",
        action: () => this.layersPanel.toggleLayer(LayerType.ApiTpeg)
      },
      {
        id: "LogLines",
        name: "Log lines",
        shortcut: "O",
        action: () => this.layersPanel.toggleLayer(LayerType.LogLines)
      },
      {
        id: "JSON",
        name: "JSON",
        shortcut: "J",
        action: () => this.layersPanel.toggleLayer(LayerType.JSON)
      },
      {
        id: "GeoJSON",
        name: "GeoJSON",
        action: () => this.layersPanel.toggleLayer(LayerType.GeoJSON)
      },
      {
        id: "GPX",
        name: "GPX",
        shortcut: "X",
        action: () => this.layersPanel.toggleLayer(LayerType.GPX)
      },
      {
        id: "TTPOther",
        name: "TTP: other data (1/sec)",
        action: () => this.layersPanel.toggleLayer(LayerType.TTPOther)
      },
      {
        id: "TTPPrediction",
        name: "TTP: other data (100/sec)",
        action: () => this.layersPanel.toggleLayer(LayerType.TTPPrediction)
      },
      {
        id: "TTPLocation",
        name: "TTP: GPS/GNSS",
        shortcut: "G",
        textColor: "black",
        action: () => this.layersPanel.toggleLayer(LayerType.TTPLocation)
      }
    ]
    for (const layerType in LayerType) {
      assert(
        !isRealLayer(layerType as LayerType) || layersMenuItems.find((item) => item.id === layerType),
        `Layer type ${layerType} not found in layersMenuItems list`
      )
    }
    layersMenuItems.forEach((menuItem) => {
      ;[
        Html.htmlElementIdForColor(menuItem.id),
        Html.htmlElementIdForText(menuItem.id),
        Html.htmlElementIdForStatus(menuItem.id)
      ].forEach((id) => {
        Html.getDefinedHtmlElementById(id).addEventListener("click", () => menuItem.action?.())
      })
    })
    return layersMenuItems
  }

  /**
   * Define the others  menu.
   */
  private setupOthersMenu(): MenuItem[] {
    return [
      {
        name: "Show help...",
        id: HTML_WINDOW_HELP,
        action: () => this.toggleHelpWindow()
      },
      {
        name: "What's new (release notes)...",
        id: HTML_WINDOW_RELEASE_NOTES,
        action: () => this.releaseNotesWindow.setVisible(!this.releaseNotesWindow.isVisible())
      },
      separatorMenuItem,
      {
        name: "Skip '#' comment lines while importing",
        id: "skip-comments",
        action: () => this.toggleOptionSkipComments()
      },
      {
        shortcut: "E",
        name: "Show errors/unprocessed lines during import",
        id: "auto-show-console-window",
        action: () => this.toggleOptionAutoShowConsole()
      },
      separatorMenuItem,
      {
        name: "Use Orbis Routing API...",
        id: "use-orbis-routing-api",
        action: () => this.toggleOptionUseOrbisRoutingApi()
      },
      {
        name: "Always download Routing API responses as JSON...",
        id: "download-routing-api-response",
        action: () => this.toggleOptionDownloadRoutingApiResponse()
      },
      {
        name: "Convert JSON Routing API response to GPX...",
        action: () => this.createGpxFromRoutingResponseFile()
      },
      separatorMenuItem,
      {
        name: "Enter default HTTP overhead size...",
        action: () => this.enterDefaultHttpOverheadSize()
      },
      {
        name: "Reset settings (incl. API key)...",
        action: () =>
          YesNoDialog.show(
            "<strong>Reset settings</strong><br/><br/>This will reload the page, ask for a new API key and reset the default overhead used for calculating the size of HTTP calls...<br/><br/>Are you sure?",
            () => {
              Storage.clear()
              window.location.reload()
            },
            () => {}
          )
      },
      separatorMenuItem,
      {
        name: "Activate data selector tool...",
        shortcut: "V",
        action: () => this.toolbox.resetCurrentTool()
      },
      {
        name: "Copy HTML ids to clipboard for development...",
        action: () => {
          this.consoleWindow.setVisible(true)
          const output = Html.copyAllHtmlIdsFromDocumentToClipboard()
          Logger.log.info(`HTML ids for development:\n\n${output}`)
          navigator.clipboard
            .writeText(output)
            .then(() => {
              alert("HTML id's from this application are copied to the clipboard as TypeScript declarations...")
            })
            .catch((err) => {
              Logger.log.error(`Failed to copy text to clipboard: ${JSON.stringify(err)}`)
            })
        }
      }
    ]
  }
}

export default App
