/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

/**
 * These constants are generated by the application itself. Choose the menu option
 * `Others -> Copy HTML ids to clipboard for development`
 * to copy the contents for this file to clipboard and paste it here.
 */

export const HTML_API_KEY_FORM = "api-key-form"
export const HTML_API_KEY_TOMTOM = "api-key-tomtom"
export const HTML_BUG_REPORT = "bug-report"
export const HTML_BUTTON_CLEAR_MAP = "button-clear-map"
export const HTML_BUTTON_SAVE = "button-save"
export const HTML_BUTTON_SELECT_FILES = "button-select-files"
export const HTML_BUTTON_ZOOM_IN = "button-zoom-in"
export const HTML_BUTTON_ZOOM_OUT = "button-zoom-out"
export const HTML_DATA_INPUT = "data-input"
export const HTML_DATA_INPUT_CANCEL = "data-input-cancel"
export const HTML_DATA_INPUT_SUBMIT = "data-input-submit"
export const HTML_DATA_INPUT_VALUE = "data-input-value"
export const HTML_FILTER_ALL = "filter-all"
export const HTML_FILTER_EQ = "filter-eq"
export const HTML_FILTER_GE = "filter-ge"
export const HTML_FILTER_LE = "filter-le"
export const HTML_FILTER_LEVEL_10 = "filter-level-10"
export const HTML_FILTER_LEVEL_11 = "filter-level-11"
export const HTML_FILTER_LEVEL_12 = "filter-level-12"
export const HTML_FILTER_LEVEL_13 = "filter-level-13"
export const HTML_FILTER_LEVEL_14 = "filter-level-14"
export const HTML_FILTER_LEVEL_15 = "filter-level-15"
export const HTML_FILTER_LEVEL_16 = "filter-level-16"
export const HTML_FILTER_LEVEL_17 = "filter-level-17"
export const HTML_FILTER_LEVEL_8 = "filter-level-8"
export const HTML_FILTER_LEVEL_9 = "filter-level-9"
export const HTML_INFO_DIALOG = "info-dialog"
export const HTML_INFO_DIALOG_DISMISS = "info-dialog-dismiss"
export const HTML_INFO_DIALOG_TEXT = "info-dialog-text"
export const HTML_INSPECTOR_BUTTONS = "inspector-buttons"
export const HTML_INSPECTOR_NEXT = "inspector-next"
export const HTML_INSPECTOR_PREVIOUS = "inspector-previous"
export const HTML_KEYS_FORM = "keys-form"
export const HTML_LAYERS_OVERHEAD = "layers-overhead"
export const HTML_MAP = "map"
export const HTML_MENU_ITEM_AUTO_SHOW_CONSOLE_WINDOW = "menu-item-auto-show-console-window"
export const HTML_MENU_ITEM_AUTO_SHOW_SOURCE = "menu-item-auto-show-source"
export const HTML_MENU_ITEM_AUTO_ZOOM = "menu-item-auto-zoom"
export const HTML_MENU_ITEM_DOWNLOAD_ROUTING_API_RESPONSE = "menu-item-download-routing-api-response"
export const HTML_MENU_ITEM_GENESIS = "menu-item-genesis"
export const HTML_MENU_ITEM_GPX_TIMESTAMPS = "menu-item-gpx-timestamps"
export const HTML_MENU_ITEM_HILLSHADE = "menu-item-hillshade"
export const HTML_MENU_ITEM_ORBIS = "menu-item-orbis"
export const HTML_MENU_ITEM_ROUTE_SHOW_SECTION_SPEEDS = "menu-item-route-show-section-speeds"
export const HTML_MENU_ITEM_ROUTE_SHOW_SECTION_TYPES = "menu-item-route-show-section-types"
export const HTML_MENU_ITEM_SATELLITE = "menu-item-satellite"
export const HTML_MENU_ITEM_SHOW_MOUSE_POSITION = "menu-item-show-mouse-position"
export const HTML_MENU_ITEM_SKIP_COMMENTS = "menu-item-skip-comments"
export const HTML_MENU_ITEM_TILE_GRID = "menu-item-tile-grid"
export const HTML_MENU_ITEM_TOGGLE_ALL_LAYERS = "menu-item-toggle-all-layers"
export const HTML_MENU_ITEM_USE_LOCAL_TIME = "menu-item-use-local-time"
export const HTML_MENU_ITEM_USE_ORBIS_ROUTING_API = "menu-item-use-orbis-routing-api"
export const HTML_MENU_ITEM_WINDOW_CONSOLE = "menu-item-window-console"
export const HTML_MENU_ITEM_WINDOW_HELP = "menu-item-window-help"
export const HTML_MENU_ITEM_WINDOW_INSPECTOR = "menu-item-window-inspector"
export const HTML_MENU_ITEM_WINDOW_RELEASE_NOTES = "menu-item-window-release-notes"
export const HTML_MENU_ITEM_WINDOW_SOURCE = "menu-item-window-source"
export const HTML_MENUBAR = "menubar"
export const HTML_PANEL_CONTENT_DATA_FILES = "panel-content-data-files"
export const HTML_PANEL_CONTENT_LAYERS = "panel-content-layers"
export const HTML_PANEL_DATA_FILES = "panel-data-files"
export const HTML_PANEL_DRAG_BAR_DATA_FILES = "panel-drag-bar-data-files"
export const HTML_PANEL_DRAG_BAR_FILTERS = "panel-drag-bar-filters"
export const HTML_PANEL_DRAG_BAR_LAYERS = "panel-drag-bar-layers"
export const HTML_PANEL_FILTERS = "panel-filters"
export const HTML_PANEL_HEADER_DATA_FILES = "panel-header-data-files"
export const HTML_PANEL_LAYERS = "panel-layers"
export const HTML_PROGRESS_BAR = "progress-bar"
export const HTML_PROGRESS_BAR_CONTAINER = "progress-bar-container"
export const HTML_STATUS_AUTO_SHOW_SOURCE = "status-auto-show-source"
export const HTML_STATUS_AUTO_ZOOM = "status-auto-zoom"
export const HTML_STATUS_TOGGLE_ALL = "status-toggle-all"
export const HTML_STATUS_TOGGLE_SIZE = "status-toggle-size"
export const HTML_TIMELINE = "timeline"
export const HTML_TIMELINE_CONTROLS = "timeline-controls"
export const HTML_TIMELINE_CONTROLS_BUTTONS = "timeline-controls-buttons"
export const HTML_TOOL_DATA_SELECTOR = "tool-data-selector"
export const HTML_TOOL_DISTANCE_CALCULATOR = "tool-distance-calculator"
export const HTML_TOOL_EXPORT_GPX = "tool-export-gpx"
export const HTML_TOOL_EXPORT_JSON = "tool-export-json"
export const HTML_TOOL_HELP = "tool-help"
export const HTML_TOOL_TILE_EDITOR = "tool-tile-editor"
export const HTML_TOOLBOX = "toolbox"
export const HTML_VERSION_MARKER = "version-marker"
export const HTML_WINDOW_CONSOLE = "window-console"
export const HTML_WINDOW_CONSOLE_TITLE = "window-console-title"
export const HTML_WINDOW_CONTENT_CONSOLE = "window-content-console"
export const HTML_WINDOW_CONTENT_HELP = "window-content-help"
export const HTML_WINDOW_CONTENT_INSPECTOR = "window-content-inspector"
export const HTML_WINDOW_CONTENT_PROGRESS = "window-content-progress"
export const HTML_WINDOW_CONTENT_RELEASE_NOTES = "window-content-release-notes"
export const HTML_WINDOW_CONTENT_SOURCE = "window-content-source"
export const HTML_WINDOW_HELP = "window-help"
export const HTML_WINDOW_HELP_TITLE = "window-help-title"
export const HTML_WINDOW_INSPECTOR = "window-inspector"
export const HTML_WINDOW_INSPECTOR_TITLE = "window-inspector-title"
export const HTML_WINDOW_PROGRESS = "window-progress"
export const HTML_WINDOW_RELEASE_NOTES = "window-release-notes"
export const HTML_WINDOW_RELEASE_NOTES_TITLE = "window-release-notes-title"
export const HTML_WINDOW_SOURCE = "window-source"
export const HTML_WINDOW_SOURCE_TITLE = "window-source-title"
export const HTML_WINDOW_TITLE_BAR_CONSOLE = "window-title-bar-console"
export const HTML_WINDOW_TITLE_BAR_HELP = "window-title-bar-help"
export const HTML_WINDOW_TITLE_BAR_INSPECTOR = "window-title-bar-inspector"
export const HTML_WINDOW_TITLE_BAR_RELEASE_NOTES = "window-title-bar-release-notes"
export const HTML_WINDOW_TITLE_BAR_SOURCE = "window-title-bar-source"
export const HTML_YES_NO_DIALOG = "yes-no-dialog"
export const HTML_YES_NO_DIALOG_NO = "yes-no-dialog-no"
export const HTML_YES_NO_DIALOG_TEXT = "yes-no-dialog-text"
export const HTML_YES_NO_DIALOG_YES = "yes-no-dialog-yes"
