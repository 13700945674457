/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {isParsedPolygonColor, LayerType, ParsedPolygonColor} from "../parserTypes"
import {Feature} from "geojson"
import {packedTileIdToLevel, packedTileIdToWgs} from "../../nds/nds"
import LineParser from "./lineParser"
import {HttpUtils} from "../../common/utils/httpUtils"

export class LineParserNdsLive extends LineParser {
  constructor() {
    super(
      LayerType.NdsLive,
      "NDS.Live",
      {
        "fill-opacity": 0.2,
        "fill-color": "rgb(197,70,16)",
        "fill-outline-color": "rgb(239,74,13)"
      },
      "(?:" +
        /* variant 1 */ "(?:/+nds-[A-Za-z_-]+-tiles/+[0-9]+/+tiles/+)" +
        /* variant 2 */ "|(?:/+umd-tiles/+[A-Za-z0-9_.-]+)/+" +
        /* variant 2, optional verson */ "(?:[0-9]+/+)?" +
        /* variant 2, suffixes*/ /**/ "(?:(?:tiles)|(?:headers)" +
        /* variant 2 */ ")/+" +
        /* variant 3 */ "|(?:/+ndslive/+smart-layer-service/+" +
        /* variant 3, optional */ "(?:[a-z]+/+)?" +
        /* variant 3 */ "[0-9]+/+get[A-Za-z]+ByTileId[?](?:[^&]*&)?tile[_iId]*=" +
        /* variant 3 */ ")" +
        "){n}",
      "" +
        /* variant 1 */ "(?:/+ndslive/+smart-layer-service/+" +
        /* variant 1, optional */ "(?:[a-z]+/+)?" +
        /* variant 1 */ "[0-9]+/+" +
        /* variant 1 */ "(?:" +
        /* variant 1, option 1 */ "(?:get[A-Za-z]+ByTileId)|" +
        /* variant 1, option 2 */ "(?:getServiceDefinition)" +
        /* variant 1 */ "))" +
        /* variant 2 */ "|(?:/+umd-tiles/+[A-Za-z0-9_.-]+)/+metadata/+"
    )
  }

  parseLine(fileId: string, lineNumber: number, line: string): Feature[] {
    line = this.decodeIfLineIsURI(line)
    let features: Feature[] = []
    const time = this.getDateTimeFromAnyString(line)
    const httpStatusCode = this.getHttpStatusCodeString(line)
    const sizeInBytes = this.getSizeInBytesFromLine(line)
    const usesCdn = LineParser.isLineCdnUrl(line)
    const metadata = {
      file: fileId,
      lineNumber: lineNumber,
      line: line,
      ...(httpStatusCode && {httpStatusCode: httpStatusCode}),
      ...(usesCdn && {usesCdn: true})
    }
    if (this.regexWithLocation) {
      const regex = this.regexWithLocation.replace("{n}", "([0-9]+)")
      let from = 0
      while (from < line.length) {
        const match = RegExp(new RegExp(regex)).exec(line.slice(from))
        if (!match) {
          break
        }
        const packedTileId = parseInt(match[1])
        const tileLevel = packedTileIdToLevel(packedTileId)
        const metadataWithTile = {
          ...metadata,
          tileId: packedTileId,
          tileLevel: tileLevel
        }
        const polygon = this.createPolygonFromNdsLiveTile(packedTileId, metadataWithTile, time, sizeInBytes)
        if (!polygon) {
          return features
        }
        features.push(polygon)
        from = from + match.index + match[0].length
      }
    }
    if (features.length === 0 && this.regexWithoutLocation) {
      const match = RegExp(new RegExp(this.regexWithoutLocation)).exec(line)
      if (match) {
        const featureWithoutTile = this.createFeatureWithSizeWithoutCoordinates(metadata, time, sizeInBytes)
        features.push(featureWithoutTile)
      }
    }
    return features
  }

  private createPolygonFromNdsLiveTile(
    packedTileId: number,
    metadata: any,
    time?: Date,
    sizeInBytes?: number
  ): Feature {
    let color = this.color
    if (metadata.httpStatusCode && !HttpUtils.isOk(metadata.httpStatusCode) && isParsedPolygonColor(color)) {
      color = this.modifyPolygonColorToErrorState(
        color as ParsedPolygonColor,
        HttpUtils.isError(metadata.httpStatusCode)
      )
    }
    const coordinates = packedTileIdToWgs(packedTileId)
    return this.createFeatureFromRectangle(
      coordinates.southWest,
      coordinates.northEast,
      color,
      metadata,
      time,
      sizeInBytes
    )
  }
}

export default LineParserNdsLive
