/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {BoundingBox, LongitudeLatitude} from "../common/utils/geoUtils"
import {Feature, LineString, Point as GeoJsonPoint, Position} from "geojson"
import {Tool} from "./tool"
import {Point as MapLibrePoint} from "maplibre-gl"
import MapView from "../app/mapView"
import Logger from "../common/logger"
import {DateTimeFormat, DateTimeUtils} from "../common/utils/dateTimeUtils"
import FileUtils from "../common/utils/fileUtils"
import {LayerWithoutId} from "../common/utils/mapLibreUtils"
import {LngLat, Wgs84Utils} from "../common/utils/wgs84Utils"
import RoutingUtils from "../common/utils/routingUtils"
import Storage, {Settings} from "../common/storage"

export class RouteCreatorGpxTool extends Tool {
  private points: Position[] = []

  constructor(map: MapView) {
    super("routeCreatorGpxTool", "Create/export route as GPX", map)
  }

  onClick(location: LngLat, _: MapLibrePoint) {
    this.points.push([location.lng, location.lat])
    this.refresh()
  }

  undo() {
    if (this.points.length > 0) {
      this.points.pop()
      this.refresh()
    }
  }

  clear() {
    this.points = []
    this.refresh()
  }

  getLayers(): LayerWithoutId[] {
    return [
      {
        type: "line", // Outer line, white.
        layout: {
          "line-cap": "round",
          "line-join": "round"
        },
        paint: {
          "line-width": 6,
          "line-color": "rgb(255,255,255)"
        },
        filter: ["in", "$type", "LineString"]
      },
      {
        type: "line", // Inner line.
        layout: {
          "line-cap": "round",
          "line-join": "round"
        },
        paint: {
          "line-width": 3,
          "line-color": "rgb(75,120,203)"
        },
        filter: ["in", "$type", "LineString"]
      },
      {
        type: "circle", // Outer circle, white.
        paint: {
          "circle-radius": 5,
          "circle-color": "rgb(75,120,203)"
        },
        filter: ["in", "$type", "Point"]
      },
      {
        type: "circle", // Inner circle.
        paint: {
          "circle-radius": 3,
          "circle-color": "rgb(255,255,255)"
        },
        filter: ["in", "$type", "Point"]
      }
    ]
  }

  refresh() {
    // Add all clicked points to the map.
    const features: Feature<GeoJsonPoint | LineString>[] = this.points.map((point) => ({
      type: "Feature",
      geometry: {type: "Point", coordinates: point},
      properties: {}
    }))
    this.draw(features)

    // If more than 1 point, calculate a route an display its lines.
    if (this.points.length > 1) {
      // Calculate the route.
      RoutingUtils.apiRequestCalculateRouteForCarWithDefaults(
        this.points,
        (jsonRoutingResponse) => {
          if (!jsonRoutingResponse || jsonRoutingResponse.routes.length < 1) {
            return
          }

          // Show the route on the map.
          const route = jsonRoutingResponse.routes[0]
          Logger.log.info(`Calculate route:\n${JSON.stringify([route.summary])}`)

          const routePoints = route.legs.flatMap((leg: any) =>
            leg.points.map((point: any) => [point.longitude, point.latitude] as [number, number])
          )
          features.push({
            type: "Feature",
            geometry: {type: "LineString", coordinates: routePoints},
            properties: {}
          })
          this.draw(features)

          // Create a GPX structure from the route response.
          const gpxContents = this.createGpxFromRoute(this.points, jsonRoutingResponse)
          if (gpxContents) {
            // Trigger download of the GOX file (nay show download dialog).
            FileUtils.triggerRateLimitedDownloadFile(FileUtils.createTimeBasedFilename("route", "gpx"), gpxContents)
          }
        },
        Storage.get(Settings.UseOrbisRoutingApi)
      )
    }
  }

  createGpxFromRoutingResponse(fileName: string, contents: string): BoundingBox | undefined {
    try {
      const gpxContents = this.createGpxFromRoute(this.points, JSON.parse(contents))
      if (gpxContents) {
        FileUtils.triggerRateLimitedDownloadFile(FileUtils.createTimeBasedFilename("route", "gpx"), gpxContents)
      }
    } catch (e) {
      Logger.log.error(
        `Error parsing JSON as routing response:
${e}
Are you sure this is a TomTom Routing API response?`
      )
    }
    return undefined
  }

  private readonly formatPoint = (
    pointType: string,
    point: LongitudeLatitude,
    indentLevel: number,
    routeTimeInSecs?: number
  ) => {
    const indent = (level: number) => "  ".repeat(level)
    let elm = `\n${indent(indentLevel)}<${pointType} lon="${point.longitude}" lat="${point.latitude}">`
    if (routeTimeInSecs !== undefined) {
      const currentDateTime = new Date()
      currentDateTime.setSeconds(currentDateTime.getSeconds() + Math.floor(routeTimeInSecs ?? 0))
      const timeISO = currentDateTime.toISOString()
      elm += `<time>${timeISO}</time>`
    }
    elm += `</${pointType}>`
    return elm
  }

  private createGpxFromRoute(points: Position[], routeResponse: any) {
    const dateTime = `${DateTimeUtils.formatDateWithTime(new Date(), DateTimeFormat.UTCTime)}`

    if (routeResponse.routes.length < 1) {
      return undefined
    }
    const route = routeResponse.routes[0]
    let routeTimeInSecs = 0
    const gpxHeader = `<?xml version="1.0" encoding="UTF-8"?>
<gpx version="1.1" creator="TomTom" xmlns="https://www.topografix.com/GPX/1/1">
  <metadata>
      <name>${dateTime}, GPX trace with ${points.length} waypoints</name>
      <desc>Generated by TomTom Map Data Visualizer</desc>
  </metadata>
  <rte>
    <name>Trace with ${points.length} waypoints</name>${points
      .map((point, _) =>
        this.formatPoint(
          "rtept",
          {
            longitude: point[0],
            latitude: point[1]
          },
          2
        )
      )
      .join("")}
  </rte>
  <trk>
    <trkseg>`
    const gpxFooter = `
    </trkseg>
  </trk>
</gpx>`
    let gpxPointsTotal = 0
    let distanceTotal = 0
    const routePoints = route.legs
      .flatMap((leg: any) => {
        const speed = leg.summary.lengthInMeters / leg.summary.travelTimeInSeconds
        return leg.points
          .map((point: LongitudeLatitude, pointIndex: number) => {
            ++gpxPointsTotal
            if (pointIndex > 0) {
              const prev = leg.points[pointIndex - 1]
              const start = {lng: prev.longitude, lat: prev.latitude}
              const end = {lng: point.longitude, lat: point.latitude}
              const distance = Wgs84Utils.distanceInMeters(start, end)
              const time = distance / speed
              distanceTotal += distance
              routeTimeInSecs += time
            }
            return this.formatPoint(
              "trkpt",
              point,
              3,
              Storage.get(Settings.AddGpxTimestamps) ? routeTimeInSecs : undefined
            )
          })
          .join("")
      })
      .join("")
    Logger.log.info(
      `Created GPX route: ${points.length} waypoints, ${gpxPointsTotal} points, ${Math.round(distanceTotal / 1000)} km, ${DateTimeUtils.formatTimeAsDuration(routeTimeInSecs)}`
    )
    return gpxHeader + routePoints + gpxFooter
  }
}

export default RouteCreatorGpxTool
