/*
 * © 2025 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

export abstract class Dialog {
  private static originalKeyDownHandler?: (event: KeyboardEvent) => void

  protected static suspendEventsHandlers() {
    this.originalKeyDownHandler = document.onkeydown ? document.onkeydown : undefined
    document.onkeydown = (event: KeyboardEvent) => event.preventDefault()
  }

  protected static resumeEventsHandlers() {
    document.onkeydown = this.originalKeyDownHandler ? this.originalKeyDownHandler : null
  }
}

export default Dialog
