/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {Feature} from "geojson"
import Parser from "../parser"

/**
 * This class is the base class for all line parsers.
 */
export abstract class LineParser extends Parser {
  /**
   * Parse a single line of text.
   *
   * @param fileId Unique ID (not just the filename) of file to parse.
   * @param lineNumber Line number of line to parse (used for messages).
   * @param line Line of text to parse.
   * @returns Array of GeoJSON features or undefined if parsing failed or no features were detected.
   */
  abstract parseLine(fileId: string, lineNumber: number, line: string): Feature[]

  /**
   * This function checks if a URL includes the use of a known CDN or not.
   * @param line The line to check.
   * @returns True if the line uses a known CDN, false otherwise.
   */
  static isLineCdnUrl(line: string) {
    const typicalCdnRegexes = [
      // Typical CDN urls:
      /https?:\/\/[a-z0-9.]+\.akamaized\.net\//,
      /https?:\/\/[a-z0-9.]+\.azureedge\.net\//,
      /https?:\/\/[a-z0-9.]+\.cdn\./,
      /https?:\/\/[a-z0-9.]+\.cloudflare\.net\//,
      /https?:\/\/[a-z0-9.]+\.cloudfront\.net\//
    ]
    return typicalCdnRegexes.reduce((acc, regex) => acc || regex.test(line), false)
  }

  protected decodeIfLineIsURI(line: string): string {
    // Note that the line may contains more than just the URI.
    if (/https?:\/\//.test(line)) {
      return decodeURIComponent(line)
    } else {
      return line
    }
  }
}

export default LineParser
