/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {Feature, FeatureCollection} from "geojson"
import {LayerWithoutId} from "../common/utils/mapLibreUtils"

/**
 * This class defines a map overlay that consists of GeoJSON features. It's used
 * to display anything on the map canvas.
 */
export class MapDataOverlay {
  readonly source: string

  // Insert the overlay before this layer.
  private readonly beforeLayerId: string

  // Function to add a layer and return its ID.
  private readonly addLayer: (layer: LayerWithoutId, beforeLayerId: string) => string

  // Function to remove a layer.
  private readonly removeLayer: (id: string) => void

  // Function to set the data source of features for the map.
  private readonly setSourceFeatureCollection: (featureCollection: FeatureCollection) => void

  // The layers and layerIds go hand-in-hand, the indices match.
  private layers: LayerWithoutId[] = []
  private layerIds: string[] = []

  constructor(
    source: string,
    beforeLayerId: string,
    addLayer: (layer: any, beforeLayerId: string) => string,
    removeLayer: (id: string) => void,
    setSourceFeatureCollection: (featureCollection: FeatureCollection) => void
  ) {
    this.source = source
    this.beforeLayerId = beforeLayerId
    this.addLayer = addLayer
    this.removeLayer = removeLayer
    this.setSourceFeatureCollection = setSourceFeatureCollection
  }

  /**
   * Set the features of the overlay.
   * @param features The features to set.
   */
  setFeatures(features: Feature[]) {
    this.setSourceFeatureCollection({
      type: "FeatureCollection",
      features: features
    })
  }

  /**
   * Remove all added layers and replace them with new ones.
   * @param newLayers The new layers to add.
   */
  replaceAllLayers(newLayers: LayerWithoutId[]) {
    // Remove all layers.
    this.layerIds.forEach((layerId) => this.removeLayer(layerId))
    this.layerIds = []
    this.layers = []

    // Add new layers.
    newLayers.forEach((layer) => {
      this.addLayerWithoutId(layer)
    })
  }

  /**
   * Add a layer definition without an ID (the ID is created when the layer is added).
   * @param layer The layer to add.
   */
  addLayerWithoutId(layer: LayerWithoutId) {
    const layerWithSource = {...layer, source: this.source}
    const layerId = this.addLayer(layerWithSource, this.beforeLayerId)
    this.layers.push(layerWithSource)
    this.layerIds.push(layerId)
  }
}

export default MapDataOverlay
