/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {BoundingBox} from "../common/utils/geoUtils"
import {Event, TimeRange} from "./timeline"
import {Feature} from "geojson"
import MapDataOverlay from "./mapDataOverlay"
import {
  autoShowWhenDataAvailable,
  duplicatesAreOkAfterSomeTime,
  duplicatesCanBeErrors,
  LayerType,
  layerTypeSupportsLevel
} from "../parsers/parserTypes"
import FileParserGeoJson from "../parsers/file/fileParserGeoJson"
import FileParserGpx from "../parsers/file/fileParserGpx"
import FileParserJson from "../parsers/file/fileParserJson"
import LineParserMapVis from "../parsers/line/lineParserMapVis"
import LineParserNdsLive from "../parsers/line/lineParserNdsLive"
import LineParserNavTiles from "../parsers/line/lineParserNavTiles"
import LineParserLogLines from "../parsers/line/lineParserLogLines"
import FileParserTtp from "../parsers/file/fileParserTtp"
import DataStore, {
  LayerSize,
  LayerState,
  SourceLineWithTime,
  TileLevelFilter,
  TileLevelFilterState,
  TIMESTAMP_ALWAYS_HIDE,
  TIMESTAMP_ALWAYS_SHOW
} from "../global/dataStore"
import {MetadataStore} from "../global/metadataStore"
import MapView from "./mapView"
import LineParserApiService from "../parsers/line/lineParserApiService"
import LineParser from "../parsers/line/lineParser"
import FileParser from "../parsers/file/fileParser"
import Parser from "../parsers/parser"
import ProgressWindow from "../windows/progressWindow"
import assert from "../common/assert"
import LineParserNdsClassic from "../parsers/line/lineParserNdsClassic"
import Logger from "../common/logger"
import AsyncUtils from "../common/utils/asyncUtils"
import {HttpUtils} from "../common/utils/httpUtils"
import LogcatUtils from "../common/utils/logcatUtils"
import MemoryUtils from "../common/utils/memoryUtils"
import {LayerWithoutId} from "../common/utils/mapLibreUtils"
import Storage, {Settings} from "../common/storage"

export type LayerStates = Record<LayerType, LayerState>
export type LayerStateAndSize = LayerState & LayerSize
export type LayerStatesAndSizes = Record<LayerType, LayerStateAndSize>

/**
 * The DataCanvas class is the overlay of the map that accepts dropped data and display it using parsers.
 * It relies heavily on on all parser and the map overlay to display the data.
 */
export class DataCanvas {
  // Stores per layer whether the layer should be shown or not, its size and whether it supports tile levels.
  layerStates: LayerStates

  // Store the tile level filter state.
  tileLevelFilterState: TileLevelFilterState
  // Stores the line parsers for each layer type.
  readonly lineParsers: Partial<Record<LayerType, LineParser>>
  // Stores the file parsers for each layer type.
  readonly fileParsers: Partial<Record<LayerType, FileParser>>
  // The map and the map data overlay.
  private readonly map: MapView
  private readonly mapDataOverlay: MapDataOverlay
  // The progress window to show progress while importing dropped files.
  private readonly progressWindow: ProgressWindow
  // The volatile layers are allowed to request the same tile when more than <n> seconds apart.
  private readonly minSecondsBetweenVolatileFeatures = 1.5 * 60
  // The initial layer states, used to reset settings.
  private readonly initialLayerStates: LayerStatesAndSizes
  // Currently active time range.
  private readonly activeTimeRange: TimeRange

  constructor(map: MapView, progressWindow: ProgressWindow) {
    this.map = map
    this.mapDataOverlay = map.createOverlayForSource("canvas", this.getLayers())
    this.progressWindow = progressWindow
    this.activeTimeRange = {minMillis: 0, maxMillis: 0}

    // Initialize all layer states.
    this.initialLayerStates = {
      _Total: this.initialLayerStateAndSize(LayerType._Total),
      _MapVis: this.initialLayerStateAndSize(LayerType._MapVis),
      MapVis3D: this.initialLayerStateAndSize(LayerType.MapVis3D),
      MapVisBasicMap: this.initialLayerStateAndSize(LayerType.MapVisBasicMap),
      MapVisFlow: this.initialLayerStateAndSize(LayerType.MapVisFlow),
      MapVisHillshade: this.initialLayerStateAndSize(LayerType.MapVisHillshade),
      MapVisIncidents: this.initialLayerStateAndSize(LayerType.MapVisIncidents),
      MapVisSatellite: this.initialLayerStateAndSize(LayerType.MapVisSatellite),
      MapVisStyle: this.initialLayerStateAndSize(LayerType.MapVisStyle),

      _Navigation: this.initialLayerStateAndSize(LayerType._Navigation),
      NdsLive: this.initialLayerStateAndSize(LayerType.NdsLive),
      NdsClassicRegion: this.initialLayerStateAndSize(LayerType.NdsClassicRegion),
      NdsClassicOther: this.initialLayerStateAndSize(LayerType.NdsClassicRegion),
      Nk2NavTiles: this.initialLayerStateAndSize(LayerType.Nk2NavTiles),
      Nk2LaneTiles: this.initialLayerStateAndSize(LayerType.Nk2LaneTiles),

      _Api: this.initialLayerStateAndSize(LayerType._Api),
      ApiAutoComplete: this.initialLayerStateAndSize(LayerType.ApiAutoComplete),
      ApiEv: this.initialLayerStateAndSize(LayerType.ApiEv),
      ApiParking: this.initialLayerStateAndSize(LayerType.ApiParking),
      ApiRevGeocode: this.initialLayerStateAndSize(LayerType.ApiRevGeocode),
      ApiRouting: this.initialLayerStateAndSize(LayerType.ApiRouting),
      ApiSearch: this.initialLayerStateAndSize(LayerType.ApiSearch),
      ApiTpeg: this.initialLayerStateAndSize(LayerType.ApiTpeg),

      _Other: this.initialLayerStateAndSize(LayerType._Other),
      LogLines: this.initialLayerStateAndSize(LayerType.LogLines),
      GeoJSON: this.initialLayerStateAndSize(LayerType.GeoJSON),
      GPX: this.initialLayerStateAndSize(LayerType.GPX),
      JSON: this.initialLayerStateAndSize(LayerType.JSON),
      TTPOther: this.initialLayerStateAndSize(LayerType.TTPOther),
      TTPPrediction: this.initialLayerStateAndSize(LayerType.TTPPrediction),
      TTPLocation: this.initialLayerStateAndSize(LayerType.TTPLocation)
    }

    // Copy the initial layer states to the current layer states and set the filter.
    this.layerStates = structuredClone(this.initialLayerStates)
    this.tileLevelFilterState = {
      level: 10,
      tileLevelFilter: TileLevelFilter.GreaterEqual
    }

    // Declare all parsers.
    //
    // IMPORTANT: The order in which they are declared is important as parsing stops when a parser returns
    // ---------- features. So, most used parsers should be declared first. Do not just sort them alphabetically.
    this.lineParsers = {
      // Try the log line parser first, to not bother the other parsers with logcat files.
      LogLines: new LineParserLogLines(),

      // Then try the rest (order is more or less most used to least used):
      MapVisIncidents: new LineParserMapVis(LayerType.MapVisIncidents),
      MapVis3D: new LineParserMapVis(LayerType.MapVis3D),
      MapVisBasicMap: new LineParserMapVis(LayerType.MapVisBasicMap),
      MapVisHillshade: new LineParserMapVis(LayerType.MapVisHillshade),
      NdsLive: new LineParserNdsLive(),
      Nk2NavTiles: new LineParserNavTiles(LayerType.Nk2NavTiles),
      Nk2LaneTiles: new LineParserNavTiles(LayerType.Nk2LaneTiles),
      MapVisSatellite: new LineParserMapVis(LayerType.MapVisSatellite),
      MapVisStyle: new LineParserMapVis(LayerType.MapVisStyle),
      MapVisFlow: new LineParserMapVis(LayerType.MapVisFlow),
      ApiAutoComplete: new LineParserApiService(LayerType.ApiAutoComplete),
      ApiEv: new LineParserApiService(LayerType.ApiEv),
      ApiParking: new LineParserApiService(LayerType.ApiParking),
      ApiRevGeocode: new LineParserApiService(LayerType.ApiRevGeocode),
      ApiRouting: new LineParserApiService(LayerType.ApiRouting),
      ApiSearch: new LineParserApiService(LayerType.ApiSearch),
      ApiTpeg: new LineParserApiService(LayerType.ApiTpeg),
      NdsClassicRegion: new LineParserNdsClassic(LayerType.NdsClassicRegion),
      NdsClassicOther: new LineParserNdsClassic(LayerType.NdsClassicOther)
    }
    this.fileParsers = {
      JSON: new FileParserJson(),
      GeoJSON: new FileParserGeoJson(),
      GPX: new FileParserGpx(),
      TTPOther: new FileParserTtp(LayerType.TTPOther),
      TTPPrediction: new FileParserTtp(LayerType.TTPPrediction),
      TTPLocation: new FileParserTtp(LayerType.TTPLocation)
    }
  }

  /**
   * Remove all data and reset to the initial state.
   */
  clear() {
    DataStore.clear()
    this.layerStates = structuredClone(this.initialLayerStates)
    this.activeTimeRange.minMillis = 0
    this.activeTimeRange.maxMillis = 0
  }

  /**
   * This clears the data from the conditional MapLibre layers and resets the time range.
   * Meaning: previous selected time range conditions no longer apply.
   */
  resetTimeRange() {
    this.activeTimeRange.minMillis = 0
    this.activeTimeRange.maxMillis = 0
    this.mapDataOverlay.replaceAllLayers(this.getLayers())
  }

  /**
   * Sets the time range for the data to be displayed. Times are in milliseconds after epoch
   * as returned by Date.getTime() and property "time" in the GeoJSON features.
   * Important: This must be a lambda and not a method to avoid binding issues.
   * @param timeRange Time range.
   */
  setActiveTimeRange = (timeRange: TimeRange) => {
    // Configure the layers to filter on time.
    const layers = this.getLayers()
    this.activeTimeRange.minMillis = timeRange.minMillis
    this.activeTimeRange.maxMillis = timeRange.maxMillis
    if (timeRange.minMillis < timeRange.maxMillis) {
      layers.forEach((layer) => {
        layer.filter = [
          "all",
          [
            "any",
            ["!", ["has", "time"]],
            ["==", ["get", "time"], TIMESTAMP_ALWAYS_SHOW], // Include items with time === TIMESTAMP_ALWAYS_SHOW.
            [
              "all",
              ["has", "time"],
              [">=", ["get", "time"], timeRange.minMillis],
              ["<=", ["get", "time"], timeRange.maxMillis],
              ["!=", ["get", "time"], TIMESTAMP_ALWAYS_HIDE] // Exclude items with time === TIMESTAMP_ALWAYS_HIDE.
            ]
          ],
          ...(layer.filter !== undefined ? [layer.filter] : [])
        ]
      })
    }
    this.mapDataOverlay.replaceAllLayers(layers)
  }

  /**
   * Get the corresponding events for given features.
   */
  getEventsFromFeatures() {
    const features = this.getActiveFeaturesFromDataStore()
    let events: Event[] = []
    features.forEach((feature) => {
      const time = feature.properties?.time as number
      const metadataKey = feature.properties?.metadata
      if (time && metadataKey) {
        const metadata = MetadataStore.retrieve(metadataKey)
        const sizeInBytes = metadata?.sizeInBytes as number
        const duplicates = metadata?.duplicates as number
        const location = metadata?.geoHash
        const httpStatusCode = metadata?.httpStatusCode
        const logLevel = metadata?.logLevel
        const hasWarning =
          (httpStatusCode !== undefined && HttpUtils.isWarning(httpStatusCode)) ||
          LogcatUtils.isLogLevelWarning(logLevel)
        const hasError =
          (httpStatusCode !== undefined && HttpUtils.isError(httpStatusCode)) || LogcatUtils.isLogLevelError(logLevel)
        events.push({
          timeMillis: time,
          metadata: metadataKey,
          ...(sizeInBytes !== undefined && {sizeInBytes: sizeInBytes}),
          ...(duplicates !== undefined && {duplicates: duplicates}),
          ...(location !== undefined && {hasLocation: true}),
          ...(hasWarning && {hasWarning: true}),
          ...(hasError && {hasError: true})
        })
      }
    })
    return events
  }

  /**
   * Zoom the map to the bounds of the features within the current time range.
   */
  zoomToBounds() {
    const bounds = this.getBoundsForActiveTimeRange()
    this.map.zoomToBounds(bounds)
  }

  /**
   * (Conditional) Layer definitions.
   * @returns The layers to be shown on the map.
   */
  getLayers(): LayerWithoutId[] {
    const minZoom = 1
    const maxZoom = 24
    return [
      {
        type: "fill",
        minzoom: minZoom,
        maxzoom: maxZoom,
        paint: {
          "fill-outline-color": [
            "case",
            ["has", "fill-outline-color"],
            ["get", "fill-outline-color"],
            "rgb(255,255,255)"
          ],
          "fill-color": ["case", ["has", "fill-color"], ["get", "fill-color"], "rgb(255,198,0)"],
          "fill-opacity": ["case", ["has", "fill-opacity"], ["get", "fill-opacity"], 0.15]
        },
        filter: ["all", ["==", ["geometry-type"], "Polygon"]]
      },
      {
        type: "line",
        minzoom: minZoom,
        maxzoom: maxZoom,
        paint: {
          "line-color": ["case", ["has", "fill-outline-color"], ["get", "fill-outline-color"], "rgb(255,255,255)"],
          "line-width": ["case", ["has", "line-width"], ["get", "line-width"], 0.66]
        },
        filter: ["all", ["==", ["geometry-type"], "Polygon"]]
      },
      {
        type: "line",
        minzoom: minZoom,
        maxzoom: maxZoom,
        layout: {
          "line-cap": "round",
          "line-join": "round"
        },
        paint: {
          "line-color": "rgb(93,93,93)",
          "line-width": ["+", 1, ["case", ["has", "line-width"], ["get", "line-width"], 4]],
          "line-offset": ["case", ["has", "line-offset"], ["get", "line-offset"], 0]
        },
        filter: ["all", ["==", ["geometry-type"], "LineString"], ["==", ["get", "line-outline"], true]]
      },
      {
        type: "line",
        minzoom: minZoom,
        maxzoom: maxZoom,
        layout: {
          "line-cap": "round",
          "line-join": "round"
        },
        paint: {
          "line-color": ["case", ["has", "line-color"], ["get", "line-color"], "rgb(133,133,133)"],
          "line-width": ["case", ["has", "line-width"], ["get", "line-width"], 4],
          "line-offset": ["case", ["has", "line-offset"], ["get", "line-offset"], 0],
          "line-dasharray": [3, 3]
        },
        filter: ["all", ["==", ["geometry-type"], "LineString"], ["has", "line-dasharray"]]
      },
      {
        type: "line",
        minzoom: minZoom,
        maxzoom: maxZoom,
        layout: {
          "line-cap": "round",
          "line-join": "round"
        },
        paint: {
          "line-color": ["case", ["has", "line-color"], ["get", "line-color"], "rgb(133,133,133)"],
          "line-width": ["case", ["has", "line-width"], ["get", "line-width"], 4],
          "line-offset": ["case", ["has", "line-offset"], ["get", "line-offset"], 0]
        },
        filter: ["all", ["==", ["geometry-type"], "LineString"], ["!", ["has", "line-dasharray"]]]
      },
      // Circle outline.
      {
        type: "circle",
        minzoom: minZoom,
        maxzoom: maxZoom,
        paint: {
          "circle-radius": ["+", 1, ["case", ["has", "circle-radius"], ["get", "circle-radius"], 4]],
          "circle-color": "rgb(0,0,0)"
        },
        filter: ["all", ["==", ["geometry-type"], "Point"]]
      },
      // Circle.
      {
        type: "circle",
        minzoom: minZoom,
        maxzoom: maxZoom,
        paint: {
          "circle-radius": ["case", ["has", "circle-radius"], ["get", "circle-radius"], 6],
          "circle-color": ["case", ["has", "circle-color"], ["get", "circle-color"], "rgb(42,115,48)"]
        },
        filter: ["all", ["==", ["geometry-type"], "Point"]]
      },
      // Text.
      {
        type: "symbol",
        minzoom: minZoom,
        maxzoom: maxZoom,
        layout: {
          "text-allow-overlap": true,
          "text-max-width": 99,
          "text-justify": "left",
          "text-field": ["get", "text"],
          "text-font": ["Noto-Bold"],
          "text-size": ["case", ["has", "text-size"], ["get", "text-size"], 12],
          "text-offset": [0, 0],
          "text-anchor": ["case", ["has", "text-anchor"], ["get", "text-anchor"], "center"],
          "text-rotate": ["get", "text-rotate"]
        },
        paint: {
          "text-color": ["get", "text-color"]
        },
        filter: ["all", ["has", "text"], ["==", ["geometry-type"], "Point"]]
      }
    ]
  }

  /**
   * Handles the drop event.
   * @param dragEvent The event.
   * @param onSuccess To be called once after all files were processed.
   * @param onFailure To be called if no files were processed, or no features were found.
   */
  async handleDrop(dragEvent: DragEvent, onSuccess: () => void, onFailure: () => void) {
    const files = dragEvent.dataTransfer!.files
    if (files.length > 0) {
      let bounds = BoundingBox.empty()
      let currentFileNr = 0
      this.progressWindow.show(`Importing files...`)
      for (const file of Array.from(files)) {
        currentFileNr++
        try {
          this.progressWindow.updateMessage(`Importing file ${currentFileNr} of ${files.length}: ${file.name}...`)
          const features = await this.processSingleFile(file)
          if (features.length > 0) {
            bounds = bounds.extendWithOther(DataStore.getBoundsOfDataFile(file))
          }
          if (currentFileNr === files.length) {
            // Zooming is done when setting the time range.
            onSuccess()
          }
        } catch (error) {
          Logger.log.error(`Error reading file ${file.name}: ${JSON.stringify(error)}`)
          onFailure()
        } finally {
          this.progressWindow.hide()
        }
      }
    } else {
      try {
        this.progressWindow.show(`Importing data, please wait...`)
        let data = dragEvent.dataTransfer!.getData("Text")
        if (data?.trim().length > 0) {
          const features = await this.processDroppedText(data, this.onImportSuccess, this.onImportFailure)
          if (features) {
            // Zooming is done when setting the time range.
            onSuccess()
          } else {
            Logger.log.info(`No features found, nothing to visualize`)
            onFailure()
          }
        } else {
          Logger.log.info(`No data found, nothing to visualize`)
          onFailure()
        }
      } finally {
        this.progressWindow.hide()
      }
    }
  }

  async highlightPotentialDuplicates(): Promise<void> {
    /**
     * If a duplicate is found, the original feature properties are stored in the metadata, a property 'duplicates'
     * is added and the properties are modified to show a red border around it.
     */
    const processFeatureGroup = async (potentialDuplicateFeatures: Feature[], index: number) => {
      for (const feature of potentialDuplicateFeatures) {
        let numberOfRealDuplicates = potentialDuplicateFeatures.length
        const time = feature.properties?.time
        if (time) {
          numberOfRealDuplicates = potentialDuplicateFeatures.filter(
            (otherFeature) =>
              otherFeature.properties &&
              // Filter out features from layers that allow duplicates.
              duplicatesCanBeErrors(otherFeature.properties?.layer) &&
              // Filter out features from volatile layers that are too close in time.
              (!duplicatesAreOkAfterSomeTime(otherFeature.properties?.layer) ||
                (otherFeature.properties.time &&
                  Math.abs(otherFeature.properties.time - time) < this.minSecondsBetweenVolatileFeatures * 1000))
          ).length
        }
        if (numberOfRealDuplicates > 1) {
          feature.properties = {
            ...feature.properties,
            duplicates: numberOfRealDuplicates
          }
          const metadataKey = feature.properties?.metadata
          assert(metadataKey)

          const metadata = {
            // Remember the original metadata for the feature.
            ...MetadataStore.retrieve(metadataKey),

            // ...and replace some other attributes to show this is a duplicates.
            duplicates: numberOfRealDuplicates,
            properties: {
              ...(feature.properties["fill-outline-color"] && {
                "fill-outline-color": feature.properties["fill-outline-color"]
              }),
              ...(feature.properties["line-width"] && {"line-width": feature.properties["line-width"]}),
              ...(feature.properties.text && {text: feature.properties.text}),
              ...(feature.properties["text-color"] && {"text-color": feature.properties["text-color"]}),
              ...(feature.properties["circle-color"] && {"cirlce-color": feature.properties["circle-color"]})
            }
          }
          MetadataStore.update(metadataKey, metadata)

          // Modify the feature to show a red border.
          switch (feature.geometry?.type) {
            case "LineString":
            case "Polygon":
              feature.properties = {
                // Remember the original properties for the feature...
                ...feature.properties,
                // ...and replace some other attributes to show this is a duplicates.
                "fill-outline-color": "rgb(255,47,0)",
                "line-width": Math.min(6, numberOfRealDuplicates * 1.5)
              }
              break
            case "Point":
              feature.properties = {
                // Remember the original properties for the feature...
                ...feature.properties,
                // ...and replace some other attributes to show this is a duplicates.
                text: `${numberOfRealDuplicates}x`,
                ...(!duplicatesCanBeErrors(feature.properties?.layer) && {"fill-opacity": 0})
              }
              break
          }
        }
      }
      this.onProgressHandler(index / featureMap.size)
    }

    const features = this.getActiveFeaturesFromDataStore()
    this.progressWindow.updateMessage(`Analyzing ${features.length} requests for potential duplicates...`)
    this.progressWindow.updatePercentage(0)

    // Remove existing red borders by restoring the original properties for duplicates.
    features
      .filter((feature) => feature.properties?.duplicates)
      .forEach((feature) => {
        const metadataKey = feature.properties?.metadata
        assert(metadataKey)
        const metadata = MetadataStore.retrieve(metadataKey)
        delete feature.properties?.duplicates
        delete feature.properties?.["fill-outline-color"]
        delete feature.properties?.["line-width"]
        delete feature.properties?.text
        delete feature.properties?.["text-color"]
        delete feature.properties?.["circle-color"]
        feature.properties = {
          ...feature.properties,
          ...(metadata.properties["fill-outline-color"] && {
            "fill-outline-color": metadata.properties["fill-outline-color"]
          }),
          ...(metadata.properties["line-width"] && {"line-width": metadata.properties["line-width"]}),
          ...(metadata.properties.text && {text: metadata.properties.text}),
          ...(metadata.properties["text-color"] && {"text-color": metadata.properties["text-color"]}),
          ...(metadata.properties["circle-color"] && {"circle-color": metadata.properties["circle-color"]})
        }
      })

    // First create feature groups that have the same geoHash and layer.
    const featureMap = new Map<string, Feature[]>()
    for (const feature of features) {
      if (
        feature.properties?.time && // No time, so no duplicates.
        feature.properties.time > 0 && // Special time stamp, so no duplicates.
        (this.activeTimeRange.minMillis === 0 || feature.properties.time >= this.activeTimeRange.minMillis) &&
        (this.activeTimeRange.maxMillis === 0 || feature.properties.time <= this.activeTimeRange.maxMillis)
      ) {
        const geoHash = feature.properties?.geoHash
        const layer = feature.properties?.layer
        if (geoHash && layer) {
          const key = `${layer}-${geoHash}`
          if (!featureMap.has(key)) {
            featureMap.set(key, [])
          }
          featureMap.get(key)!.push(feature)
        }
      }
    }
    const featureGroups = Array.from(featureMap.values())

    // Then check the time ranges within each group (with the same tiles).
    await AsyncUtils.processAsync(featureGroups, processFeatureGroup, this.onProgressHandler)
    this.updateMapDataOverlayFeatures()
  }

  //!! TODO: Define success handler.
  private onImportSuccess = () => {}

  //!! TODO: Define failure handler.
  private onImportFailure = () => {}

  private getActiveFeaturesFromDataStore() {
    /**
     * Get the "active" (a.k.a. selected) features from the data store, based on filter states.
     */
    return DataStore.getFeatures(this.layerStates, this.tileLevelFilterState)
  }

  /**
   * Get the bounding box for features withing a given time range.
   */
  private getBoundsForActiveTimeRange() {
    // Calculate bounding box to zoom in (pretty fast operation).
    let bounds = BoundingBox.empty()
    const features = DataStore.getFeatures(this.layerStates, this.tileLevelFilterState)
    features.forEach((feature) => {
      const time = feature.properties?.time
      const metadataKey = feature.properties?.metadata
      if (metadataKey) {
        const metadata = MetadataStore.retrieve(metadataKey)
        if (
          metadata?.bounds &&
          (!time ||
            this.activeTimeRange.minMillis === 0 ||
            this.activeTimeRange.maxMillis === 0 ||
            (this.activeTimeRange.minMillis <= time && time <= this.activeTimeRange.maxMillis))
        ) {
          bounds = bounds.extendWithOther(metadata.bounds)
        }
      }
    })
    return bounds
  }

  /**
   * Set the map data to show these features.
   */
  private updateMapDataOverlayFeatures() {
    const features = this.getActiveFeaturesFromDataStore()
    this.mapDataOverlay.setFeatures(features)
  }

  /**
   * Initial value for layer state.
   * @param layerType Layer type.
   */
  private initialLayerStateAndSize(layerType: LayerType): LayerStateAndSize {
    return {
      show: false,
      supportsLevel: layerTypeSupportsLevel.includes(layerType),
      totalCount: 0,
      apiCount: 0,
      sizeInBytes: 0
    }
  }

  /**
   * Progress handler for file import, showing a percentage slider.
   * @param percentage Percentage (0..1).
   */
  private readonly onProgressHandler = (percentage: number) => this.progressWindow.updatePercentage(percentage)

  /**
   * Process importing a single file.
   * @param file File to import.
   * @returns Features found in the file.
   */
  private async processSingleFile(file: File): Promise<Feature[]> {
    const reader = new FileReader()
    const readFile = (): Promise<string> => {
      return new Promise((resolve, reject) => {
        reader.onload = () => resolve(reader.result as string)
        reader.onerror = () => reject(reader.error)
        reader.readAsText(file)
      })
    }

    try {
      const contents = await readFile()
      return this.processSingleFileContents(file, contents)
    } catch (error) {
      Logger.log.error(`Error reading file ${file.name}: ${JSON.stringify(error)}`)
      return []
    }
  }

  /**
   * Process the contents of a single file.
   * @param file File to import.
   * @param contents Contents of the file (text lines).
   * @returns Features found in the contents.
   */
  private async processSingleFileContents(file: File, contents: string): Promise<Feature[]> {
    // Create a unique file ID.
    const fileName = file.name
    const fileId = DataStore.getFileIdFromFile(file)

    // Relace the file if it was loaded already.
    Logger.log.info("")
    if (DataStore.isFileLoaded(file)) {
      Logger.log.info(`File ${fileName} was already parsed; data cleared.`)
      DataStore.removeFile(file)
    }
    Logger.log.info(`Parsing file ${fileName}...`)

    // Parser the features, trying all parsers.
    let features: Feature[]
    if (this.fileParsers[LayerType.TTPLocation]?.recognizedFileExtension(fileName)) {
      features = await this.fileParsers[LayerType.TTPLocation].parseFile(fileId, contents, this.onProgressHandler)
      if (features.length > 0) {
        this.layerStates.TTPLocation.show = true
        this.layerStates.TTPOther.show = false
        this.layerStates.TTPPrediction.show = false
      }
    } else if (this.fileParsers[LayerType.GPX]?.recognizedFileExtension(fileName)) {
      features = await (this.fileParsers[LayerType.GPX] as FileParserGpx).parseFile(
        fileName,
        contents,
        this.onProgressHandler
      )
      if (features.length > 0) {
        this.layerStates.GPX.show = true
      }
    } else if (this.fileParsers[LayerType.GeoJSON]?.recognizedFileExtension(fileName)) {
      features = await this.fileParsers[LayerType.GeoJSON].parseFile(fileId, contents, this.onProgressHandler)
      if (features.length > 0) {
        this.layerStates.GeoJSON.show = true
      }
    } else if (this.fileParsers[LayerType.JSON]?.recognizedFileExtension(fileName)) {
      features = await this.fileParsers[LayerType.JSON].parseFile(fileId, contents, this.onProgressHandler)
      if (features.length > 0) {
        this.layerStates.JSON.show = true
      }
    } else {
      // This may be a file with different layer types per line.
      features = await this.handleDropFileContents(fileId, contents)
    }
    if (features.length === 0) {
      Logger.log.info(`No features found in file ${fileName}; nothing imported.`)
      DataStore.removeFile(file)
      return []
    }

    // Features are parsed. Now post-process to annotated the source lines with time stamps.
    const sourceLinesWithTime = this.splitContentIntoSourceLinesWithTime(features, contents)

    // Ad the features and the source lines.
    DataStore.addFeaturesAndSourceLinesWithTimes(features, sourceLinesWithTime, file)
    DataStore.setFileIsShown(fileId)
    return features
  }

  /**
   * Process the contents of a dropped text.
   * @param contents Contents of the text.
   * @param onSuccess To be called once after all files were processed.
   * @param onFailure To be called an error occurred.
   * @returns Features found in the contents.
   */
  private async processDroppedText(contents: string, onSuccess: () => void, onFailure: () => void): Promise<Feature[]> {
    // Parser features, by trying the text parser.
    contents = contents.trim()
    const fileId = DataStore.getFileIdFromFile()
    const geoJsonfeatures = (this.fileParsers[LayerType.GeoJSON] as FileParserGeoJson).parseString(contents)
    const features = geoJsonfeatures
      ? await Promise.resolve(geoJsonfeatures)
      : await this.handleDropFileContents(fileId, contents)

    // Features are parsed. Now post-process to annotated the source lines with time stamps.
    const sourceLinesWithTime = this.splitContentIntoSourceLinesWithTime(features, contents)

    // As post-processing, add the features and the source lines.
    DataStore.addFeaturesAndSourceLinesWithTimes(features, sourceLinesWithTime)
    DataStore.setFileIsShown(fileId)
    return features
  }

  /**
   * Parse a single text line and return the features found.
   * @param fileId File ID (not file name).
   * @param lineNumber Line number.
   * @param line Line to parse.
   * @returns Features found in the line.
   */
  private parseSingleTextLine(fileId: string, lineNumber: number, line: string): Feature[] {
    let features: Feature[] = []

    for (const [layerTypeString, lineParser] of Object.entries(this.lineParsers)) {
      if (lineParser) {
        const layerType = layerTypeString as LayerType
        const layerState = this.layerStates[layerType]
        const features = lineParser.parseLine(fileId, lineNumber, line)
        if (features && features.length > 0) {
          layerState.show = layerState.show || autoShowWhenDataAvailable(layerType)

          // Add the actual feature to the metadata, so it can be fetched and displayed omn the map later.
          features.forEach((feature) => {
            const metadataKey = feature.properties?.metadata
            if (metadataKey) {
              const metadata = MetadataStore.retrieve(metadataKey)
              if (!metadata.generated) {
                MetadataStore.update(metadataKey, {...metadata, feature: feature})
              }
            }
          })
          // Return when parser found something on this line, otherwise try next parser.
          return features
        }
      }
    }
    return features
  }

  /**
   * Handle the contents of a dropped file.
   * @param fileId File ID (not file name).
   * @param contents Contents of the file (text lines).
   * @returns Features found in the contents.
   */
  private async handleDropFileContents(fileId: string, contents: string): Promise<Feature[]> {
    // Replace odd characters, but don't filter out lines to keep the line numbers correct.
    const linesToParse = contents.replace(/[\r\x00]/g, "").split("\n")
    let allFeatures: Feature[] = []
    Logger.log.info("Parsing contents line by line...")
    let unprocessedLines: {line: string; lineNumber: number}[] = []

    const processLine = async (line: string, index: number) => {
      this.onProgressHandler(index / linesToParse.length)
      if (!Parser.toBeDiscardBeforeParsing(line, Storage.get(Settings.SkipComments))) {
        const featurePerLine = this.parseSingleTextLine(fileId, index, line.trim())
        if (featurePerLine.length > 0) {
          MemoryUtils.addAllElements(allFeatures, featurePerLine)
        } else {
          unprocessedLines.push({line: line, lineNumber: index + 1})
        }
      }
    }
    await AsyncUtils.processAsync(linesToParse, processLine, this.onProgressHandler)

    if (unprocessedLines.length > 0) {
      const filteredUnprocessedLines = unprocessedLines
        .filter((elm) => !Parser.toDiscardAfterParsing(elm.line))
        .map((elm) => `line ${(elm.lineNumber + 1).toString().padStart(7)}: ${elm.line}`)

      if (filteredUnprocessedLines.length > 0) {
        if (Storage.get(Settings.AutoShowConsole)) {
          Logger.log.show()
        }
        Logger.log.warning("Unprocessed lines:")
        filteredUnprocessedLines.forEach((line) => Logger.log.error(line))
        Logger.log.error(`\nWARNING: ${filteredUnprocessedLines.length} lines were not recognized or processed`)
        Logger.log.info("| If this is a HTTP log from NavSDK, please issue a change request for the visualizer.")
        Logger.log.info("| You can reach out on Slack to rijn.buve@tomtom.com for that.")
      }
    } else {
      Logger.log.ok("OK: all lines were correctly processed")
    }
    return allFeatures
  }

  /**
   * Use the features to annotate source lines in the file contents. The timestamps are taken from the lines
   * with features. If no timestamp is found for a line, the closest relevant time will be used, which is:
   * - 0 if there was none,
   * - The last time found if there was one,
   * - the first time found, if the file started without timestamps (could also be 0).
   * @param features Parsed feature from the file content (these contains line number references).
   * @param contents Full contents of file (as a single string).
   * @returns Source lines with time stamps.
   */
  private splitContentIntoSourceLinesWithTime(features: Feature[], contents: string): SourceLineWithTime[] {
    const sourceLinesWithTime: SourceLineWithTime[] = []
    const sourceLines = contents.replace("\r", "").split("\n")

    // Create a 1:1 copy of the source lines, with the time stamps added.
    sourceLines.forEach((line, index) => {
      sourceLinesWithTime.push({time: 0, line: line})
    })

    // Now, annotate the source lines referenced by features, with time stamps.
    features.forEach((feature) => {
      const time = feature.properties?.time
      const metadataKey = feature.properties?.metadata
      const metadata = MetadataStore.retrieve(metadataKey)
      const lineNumber = metadata?.lineNumber

      // If the feature is timed and has a line number, add the time stamp to the source line.
      if (time !== undefined && lineNumber !== undefined) {
        const sourceLineWithTime = sourceLinesWithTime[lineNumber]
        sourceLineWithTime.time = time
        sourceLinesWithTime[lineNumber] = sourceLineWithTime
      }
    })

    // Then, fill in the gaps (replace time === 0 with previous valid times).
    let firstTime = 0 // Store very first time, to replace leading 0's.
    let firstTimeIndex = 0
    let time = 0
    sourceLinesWithTime.forEach((sourceLineWithTime, index) => {
      if (sourceLineWithTime.time > 0) {
        time = sourceLineWithTime.time // Remember the last time stamp.
        if (!firstTime) {
          firstTime = time
          firstTimeIndex = index
        }
      } else {
        sourceLineWithTime.time = time // Fill in the gap with the last time stamp.
      }
    })

    // Finally, replace the leading 0's with the first time found.
    for (let i = 0; i < firstTimeIndex; ++i) {
      sourceLinesWithTime[i].time = firstTime
    }

    return sourceLinesWithTime
  }
}

export default DataCanvas
