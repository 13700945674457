/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {LocationIndex, TtpMessageSensorLocationBase} from "./ttpMessageSensorLocationBase"
import {TtpMessageParseException} from "../ttpMessageParseException"
import {parseNumberMandatory, parseNumberOptional, TtpMessageType} from "../ttpMessage"

export class TtpMessageSensorMapMatcherInputLocations extends TtpMessageSensorLocationBase {
  constructor(
    lineNumber: number,
    monotonicTime: number,
    message: number,
    channel: number,
    longitude: number,
    latitude: number,
    accuracy?: number,
    altitude?: number,
    bearing?: number,
    speed?: number,
    time?: number,
    quality?: number,
    lanesVisible?: number,
    laneDriven?: number
  ) {
    super(
      lineNumber,
      TtpMessageType.SensorMapMatcherInputLocations,
      monotonicTime,
      message,
      channel,
      longitude,
      latitude,
      accuracy,
      altitude,
      bearing,
      speed,
      time,
      quality,
      lanesVisible,
      laneDriven
    )
  }

  static parse(
    lineNumber: number,
    parts: string[],
    monotonicTime: number,
    message: number
  ): TtpMessageSensorMapMatcherInputLocations {
    const {
      ChannelIndex,
      LongitudeIndex,
      LatitudeIndex,
      AccuracyIndex,
      AltitudeIndex,
      BearingIndex,
      SpeedIndex,
      TimeIndex,
      QualityIndex,
      LanesVisibleIndex,
      LaneDrivenIndex
    } = LocationIndex

    if (parts.length <= LatitudeIndex) {
      throw new TtpMessageParseException(`Invalid map matcher input line (need at least lon, lat)`)
    }
    const channel = parseNumberMandatory(parts[ChannelIndex])
    const longitude = parseNumberMandatory(parts[LongitudeIndex])
    const latitude = parseNumberMandatory(parts[LatitudeIndex])
    const accuracy = parts.length > AccuracyIndex ? parseNumberOptional(parts[AccuracyIndex]) : undefined
    const altitude = parts.length > AltitudeIndex ? parseNumberOptional(parts[AltitudeIndex]) : undefined
    const bearing = parts.length > BearingIndex ? parseNumberOptional(parts[BearingIndex]) : undefined
    const speed = parts.length > SpeedIndex ? parseNumberOptional(parts[SpeedIndex]) : undefined
    const timeField = parts.length > TimeIndex ? parseNumberOptional(parts[TimeIndex]) : undefined
    const time = timeField ? Math.round(timeField * 1000) : undefined
    const quality = parts.length > QualityIndex ? parseNumberOptional(parts[QualityIndex]) : undefined
    const lanesVisible = parts.length > LanesVisibleIndex ? parseNumberOptional(parts[LanesVisibleIndex]) : undefined
    const laneDriven = parts.length > LaneDrivenIndex ? parseNumberOptional(parts[LaneDrivenIndex]) : undefined
    return new TtpMessageSensorMapMatcherInputLocations(
      lineNumber,
      monotonicTime,
      message,
      channel,
      longitude,
      latitude,
      accuracy,
      altitude,
      bearing,
      speed,
      time,
      quality,
      lanesVisible,
      laneDriven
    )
  }
}

export default TtpMessageSensorMapMatcherInputLocations
