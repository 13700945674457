/*
 * © 2025 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import Html from "../html/html"
import {
  HTML_YES_NO_DIALOG,
  HTML_YES_NO_DIALOG_NO,
  HTML_YES_NO_DIALOG_TEXT,
  HTML_YES_NO_DIALOG_YES
} from "../html/htmlElementId"
import Dialog from "./dialog"

export class YesNoDialog extends Dialog {
  /**
   * Present a simple yes/no dialog.
   *
   * @param htmlText Text/prompt to show.
   * @param onYes Yes handler.
   * @param onNo No handler. Default does nothing.
   */
  static show(htmlText: string, onYes: () => void, onNo: () => void = () => {}) {
    const addEventListeners = () => {
      Dialog.suspendEventsHandlers()
      Html.getDefinedHtmlElementById(HTML_YES_NO_DIALOG_YES).addEventListener("click", handleYes)
      Html.getDefinedHtmlElementById(HTML_YES_NO_DIALOG_NO).addEventListener("click", handleNo)
    }

    const removeEventListeners = () => {
      Dialog.resumeEventsHandlers()
      Html.getDefinedHtmlElementById(HTML_YES_NO_DIALOG_YES).removeEventListener("click", handleYes)
      Html.getDefinedHtmlElementById(HTML_YES_NO_DIALOG_NO).removeEventListener("click", handleNo)
    }

    const text = Html.getDefinedHtmlElementById(HTML_YES_NO_DIALOG_TEXT)
    text.innerHTML = htmlText
    const dialog = Html.getDefinedHtmlElementById(HTML_YES_NO_DIALOG)
    dialog.style.display = "block"

    const handleYes = () => {
      removeEventListeners()
      dialog.style.display = "none"
      onYes()
    }

    const handleNo = () => {
      removeEventListeners()
      dialog.style.display = "none"
      onNo()
    }

    addEventListeners()
  }
}

export default YesNoDialog
