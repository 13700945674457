/*
 * © 2025 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import Html from "../html/html"
import {HTML_INFO_DIALOG, HTML_INFO_DIALOG_DISMISS, HTML_INFO_DIALOG_TEXT} from "../html/htmlElementId"
import Dialog from "./dialog"

export class InfoDialog extends Dialog {
  /**
   * Show an info dialog.
   * @param htmlText The HTML text to show in the dialog.
   * @param onDismiss Callback function to call when the dialog is dismissed. Default does nothing.
   */
  static show(htmlText: string, onDismiss: () => void = () => {}) {
    const text = Html.getDefinedHtmlElementById(HTML_INFO_DIALOG_TEXT)
    text.innerHTML = htmlText
    const dialog = Html.getDefinedHtmlElementById(HTML_INFO_DIALOG)
    dialog.style.display = "block"

    const addEventListeners = () => {
      Dialog.suspendEventsHandlers()
      Html.getDefinedHtmlElementById(HTML_INFO_DIALOG_DISMISS).addEventListener("click", handleDismiss)
    }

    const removeEventListeners = () => {
      Dialog.resumeEventsHandlers()
      Html.getDefinedHtmlElementById(HTML_INFO_DIALOG_DISMISS).removeEventListener("click", handleDismiss)
    }

    const handleDismiss = () => {
      removeEventListeners()
      dialog.style.display = "none"
      onDismiss()
    }
    addEventListeners()
  }
}

export default InfoDialog
