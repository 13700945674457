/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {TtpMessageParseException} from "../ttpMessageParseException"
import TtpMessageBase from "../ttpMessageBase"
import {TtpMessageType} from "../ttpMessage"
import Logger from "../../../common/logger"

const REGEX_HEADER = "^\\s*BEGIN\\s*:\\s*ApplicationVersion\\s*=(?:[^0-9]*)([0-9.]+).*$"

export class TtpMessageHeader extends TtpMessageBase {
  type: TtpMessageType = TtpMessageType.Header
  version: string

  constructor(lineNumber: number, version: string) {
    super(lineNumber)
    this.version = version
  }

  static parse(lineNumber: number, input: string): TtpMessageHeader | undefined {
    const match = RegExp(REGEX_HEADER).exec(input)
    if (!match) {
      return undefined
    }
    if (match.length < 2) {
      throw new TtpMessageParseException(`Invalid header (needs at least 2 fields)`)
    }
    const versionString = match[1].trim()
    const version = parseFloat(versionString)
    if (!version || version <= 0.1) {
      const message = `Unrecognized file format version: ${versionString} (needs to be > 0.1)`
      Logger.log.info(message)
      throw new TtpMessageParseException(message)
    }

    return new TtpMessageHeader(lineNumber, versionString)
  }
}

export default TtpMessageHeader
