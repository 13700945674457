/*
 * © 2024 TomTom NV. All rights reserved.
 *
 * This software is the proprietary copyright of TomTom NV and its subsidiaries and may be
 * used for internal evaluation purposes or commercial use strictly subject to separate
 * license agreement between you and TomTom NV. If you are the licensee, you are only permitted
 * to use this software in accordance with the terms of your license agreement. If you are
 * not the licensee, you are not authorized to use this software in any manner and should
 * immediately return or destroy it.
 */

import {
  HTML_MENUBAR,
  HTML_PANEL_DATA_FILES,
  HTML_PANEL_FILTERS,
  HTML_PANEL_LAYERS,
  HTML_TIMELINE,
  HTML_TOOLBOX
} from "../html/htmlElementId"
import {
  HTML_PROPERTY_PANEL_DATA_FILES_TOP,
  HTML_PROPERTY_PANEL_FILTERS_TOP,
  HTML_PROPERTY_PANEL_LAYERS_MAX_HEIGHT,
  HTML_PROPERTY_PANEL_LAYERS_TOP,
  HTML_PROPERTY_TOOLBOX_TOP
} from "../html/htmlPropertyId"
import Html from "../html/html"
import DataCanvas from "../app/dataCanvas"
import Timeline from "../app/timeline"
import MenuBar from "../menu/menuBar"

/**
 * This class defines panels, like the layers panel, filters panel, data files panel, etc.
 */
export abstract class Panel {
  protected readonly dataCanvas: DataCanvas
  protected readonly timeline: Timeline
  protected readonly onDraw: (inhibitAutoZoom?: boolean, onFinished?: () => void) => void

  constructor(
    dataCanvas: DataCanvas,
    timeline: Timeline,
    onDraw: (inhibitAutoZoom?: boolean, onFinished?: () => void) => void
  ) {
    this.dataCanvas = dataCanvas
    this.timeline = timeline
    this.onDraw = onDraw

    window.addEventListener("resize", this.resizePanels)
  }

  /**
   * Callback function for resizing the main window (and re-adjusting panels if needed).
   * This also resets the position of the toolbox.
   */
  public readonly resizePanels = () => {
    const menubar = Html.getDefinedHtmlElementById(HTML_MENUBAR)
    const toolbox = Html.getDefinedHtmlElementById(HTML_TOOLBOX)
    const filtersPanel = Html.getDefinedHtmlElementById(HTML_PANEL_FILTERS)
    const layersPanel = Html.getDefinedHtmlElementById(HTML_PANEL_LAYERS)
    const dataFilesPanel = Html.getDefinedHtmlElementById(HTML_PANEL_DATA_FILES)
    const timeline = Html.getDefinedHtmlElementById(HTML_TIMELINE)

    // !! TODO [techdebt]: Set locations of children in their own class.
    // The code below sets the properties if the CSS file. These are only picked up by the panels
    // if they were not manually moved by the user (and their position was set to an absolute coordinate).
    const gap = 5
    const toolboxTop = menubar.offsetTop + menubar.offsetHeight + gap
    toolbox.style.setProperty(HTML_PROPERTY_TOOLBOX_TOP, `${toolboxTop}px`)

    const panelFiltersTop = toolbox.offsetTop + toolbox.offsetHeight + gap
    filtersPanel.style.setProperty(HTML_PROPERTY_PANEL_FILTERS_TOP, `${panelFiltersTop}px`)

    const panelLayersTop = filtersPanel.offsetTop + filtersPanel.offsetHeight + gap
    const panelLayersMaxHeight = timeline.offsetTop - panelLayersTop - dataFilesPanel.offsetHeight - gap
    layersPanel.style.setProperty(HTML_PROPERTY_PANEL_LAYERS_TOP, `${panelLayersTop}px`)
    layersPanel.style.setProperty(HTML_PROPERTY_PANEL_LAYERS_MAX_HEIGHT, `${panelLayersMaxHeight}px`)

    const panelDataFilesTop = layersPanel.offsetTop + layersPanel.offsetHeight + gap
    dataFilesPanel.style.setProperty(HTML_PROPERTY_PANEL_DATA_FILES_TOP, `${panelDataFilesTop}px`)
  }

  protected readonly updateAnyOption = (
    id: string,
    value: boolean,
    changeOnOffText: boolean = true,
    textOn?: string,
    textOff?: string
  ) => {
    // Some options have a menu equivalent, some don't.
    const menuItem = Html.htmlElementIdForMenuItem(id)
    if (document.getElementById(menuItem)) {
      MenuBar.updateMenuItemToggle(Html.htmlElementIdForMenuItem(id), value)
    }

    const cell = Html.getDefinedHtmlElementById(Html.htmlElementIdForStatus(id))
    if (changeOnOffText) {
      cell.innerText = value ? textOn ?? "ON" : textOff ?? "OFF"
    }
    cell.classList.remove("off", "on")
    cell.classList.add(value ? "on" : "off")
  }
}

export default Panel
